import { types } from 'mobx-state-tree'



export const MembersProAm = types
  .model({
    id: types.optional(types.string, ''),
    surName: types.maybeNull(types.string),
    firstName: types.maybeNull(types.string),
    date: types.maybeNull(types.string),
    status: types.maybeNull(types.string),
    club: types.maybeNull(types.string),
    country: types.maybeNull(types.string),
    actualYear: types.maybeNull(types.number),
    isPaymentActualYear: types.optional(types.boolean, false),
    nextYear: types.maybeNull(types.number),
    isPaymentNextYear: types.optional(types.boolean, false),
    lastPaidYear: types.optional(types.number, 0),
  })
  .views(self => ({
    get statusString() {
      let statusString = ''

      if (self.status === 'A') statusString = 'Amatér'
      if (self.status === 'P') statusString = 'Profesionál'
      if (self.status === 'J') statusString = 'Porotce'
      if (self.status === 'C') statusString = 'Člen'

      return statusString
    }
  }))
  .actions(self => ({
    updateMemberFromData(data: any) {

      self.id = data.id
      self.surName = data.surName
      self.firstName = data.firstName
      self.date = data.date
      self.club = data.club
      self.country = data.country
      self.lastPaidYear = data.lastPaidYear
      self.status = data.status
    }

  }))
