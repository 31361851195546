import { types } from 'mobx-state-tree'

const Service = types.model('Service',{
  id: types.maybeNull(types.string),
  name: types.maybeNull(types.string),
  code: types.maybeNull(types.string)
})

export const CustomerOpenedServices = types
  .model({
    service: types.frozen(Service),
    validTo: types.maybeNull(types.string),
  })
  .views(self => ({
    get isActive() {

      return new Date(self.validTo!.replace(' ', 'T')) > new Date() ? true:false
    }
  }))
  .actions(self => ({
    

  }))
