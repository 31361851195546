import { types } from 'mobx-state-tree'

const Levels = types.model('Levels',{
    idMemberLevels: types.optional(types.string, ''),
    idMembers: types.optional(types.string, ''),
    idLevels: types.maybeNull(types.string),
    points: types.maybeNull(types.string),
    finals: types.maybeNull(types.string),
    active: types.maybeNull(types.string),
    styleId: types.maybeNull(types.string),
    styleName: types.maybeNull(types.string),
    styleShortcut: types.maybeNull(types.string),
    classShortcut: types.maybeNull(types.string)
})

const Payments = types.model('Payments',{
  id: types.optional(types.string, ''),
  idMembers: types.optional(types.string, ''),
  idTransaction: types.maybeNull(types.string),
  createDate: types.optional(types.string,''),
  variableSymbol: types.maybeNull(types.string),
  year: types.maybeNull(types.number),
  price: types.maybeNull(types.number),
  note: types.maybeNull(types.string)
})

export const DancerList = types
  .model({
    idMembers: types.optional(types.string, ''),
    status: types.optional(types.string, ''),
    surName: types.maybeNull(types.string),
    firstName: types.maybeNull(types.string),
    birthdate: types.maybeNull(types.string),
    personalIdentificationNumber: types.maybeNull(types.string),
    club: types.maybeNull(types.string),
    country: types.maybeNull(types.string),
    email: types.maybeNull(types.string),
    phone: types.maybeNull(types.string),
    street: types.maybeNull(types.string),
    houseNumber: types.maybeNull(types.string),
    identificationNumber: types.maybeNull(types.string),
    postalCode: types.maybeNull(types.string),
    city: types.maybeNull(types.string),
    yesOne: types.optional(types.boolean, false),
    yesTwo: types.optional(types.boolean, false),
    yesThree: types.optional(types.boolean, false),
    yesFour: types.optional(types.boolean, false),


    membershipTo: types.maybeNull(types.string),
    medicalExaminationTo: types.maybeNull(types.string),
    registered: types.maybeNull(types.string),
    active: types.maybeNull(types.string),  
    lastPaidYear: types.optional(types.number, 0),
    levels: types.array(Levels),
    payments: types.array(Payments)

  })
  .views(self => ({
    get memberAfterMedicalExamination() {
      
      return new Date(self.medicalExaminationTo!).setHours(0, 0, 0, 0) >= new Date().setHours(0, 0, 0, 0) ? false:true
    },
    get memberAfterMembership() {
      
      return new Date(self.membershipTo!).setHours(0, 0, 0, 0) >= new Date().setHours(0, 0, 0, 0) ? false:true
    },
    get searchString() {
      //eslint-disable-next-line
      let searchString:string = ''

      let fullName1:string = ''
      if (self.firstName === null || self.firstName === '') fullName1 = self.surName! 
      else fullName1 = (self.surName! === null ? '' : self.surName + ' ' ) + self.firstName

      let club:string = ''
      if (self.club !== null) club = self.club

      let country:string = ''
      if (self.country !== null) country = self.country

      return searchString = fullName1+' '+club+' '+country
    },
    get searchStringId() {
      //eslint-disable-next-line
      let searchString:string = ''

      let search1:string = ''
      if (self.personalIdentificationNumber === null || self.personalIdentificationNumber === '') search1 = self.idMembers! 
      else search1 = self.idMembers + ' ' + self.personalIdentificationNumber


      return searchString = search1
    },
    get statusString() {
      let statusString = ''

      if (self.status === 'A') statusString = 'Amatér'
      if (self.status === 'P') statusString = 'Profesionál'
      if (self.status === 'J') statusString = 'Porotce'
      if (self.status === 'C') statusString = 'Člen'

      return statusString
    }
  }))
  .actions(self => ({
    

  }))
