import { types } from 'mobx-state-tree'


export const OnlineActions = types
  .model({
    uid: types.optional(types.string, ''),
    idActions: types.maybeNull(types.number),
    actionName: types.maybeNull(types.string),
    actionDate: types.maybeNull(types.string),
    actionDateTo: types.maybeNull(types.string),
    
  })
  .views(self => ({
    get competitionAfterTerm() {
      console.log(new Date(self.actionDateTo !== null ? self.actionDateTo:self.actionDate!).setHours(0, 0, 0, 0))
      console.log(new Date().setHours(0, 0, 0, 0))
      if (new Date(self.actionDateTo !== null ? self.actionDateTo:self.actionDate!).setHours(0, 0, 0, 0) > new Date().setHours(0, 0, 0, 0)) {console.log(false)} else {console.log(true)}

      return new Date(self.actionDateTo !== null ? self.actionDateTo:self.actionDate!).setHours(0, 0, 0, 0) >= new Date().setHours(0, 0, 0, 0) ? false:true
    },
  }))
  .actions(self => ({
    

  }))
