import { types } from 'mobx-state-tree'



const Contacts = types.model('Contacts',{
  id: types.optional(types.string, ''),
  type: types.optional(types.string, ''),
  value: types.optional(types.string, ''),
  note: types.maybeNull(types.string),
  isBilling: types.optional(types.boolean, false)
})

const BillingAddress = types.model('BillingAddress',{
  street: types.maybeNull(types.string),
  houseNumber: types.maybeNull(types.string),
  identificationNumber: types.maybeNull(types.string),
  postalCode: types.maybeNull(types.string),
  city: types.maybeNull(types.string)
})

const DeliveryAddress = types.model('DeliveryAddress',{
  name: types.maybeNull(types.string),
  title: types.maybeNull(types.string),
  firstName: types.maybeNull(types.string),
  surName: types.maybeNull(types.string),
  titleAfter: types.maybeNull(types.string),
  street: types.maybeNull(types.string),
  houseNumber: types.maybeNull(types.string),
  identificationNumber: types.maybeNull(types.string),
  postalCode: types.maybeNull(types.string),
  city: types.maybeNull(types.string),
  phoneNumber: types.maybeNull(types.string)
})


export const CustomerOpened = types
  .model({
    id: types.optional(types.string, ''),
    accountNumber: types.maybeNull(types.string),
    businessName: types.maybeNull(types.string),
    name: types.maybeNull(types.string),
    companyRegistrationNumber: types.maybeNull(types.string),
    taxIdentificationNumber: types.maybeNull(types.string),
    isTaxAble: types.optional(types.boolean, false),
    isActive: types.optional(types.boolean, false),
    gdprDate: types.maybeNull(types.string),

    title: types.maybeNull(types.string),
    firstName: types.maybeNull(types.string),
    surName: types.maybeNull(types.string),
    titleAfter: types.maybeNull(types.string),
    
    contacts: types.array(Contacts),
    billingAddress: types.frozen(BillingAddress),
    deliveryAddress: types.maybeNull(DeliveryAddress)
 
    //count: types.optional(types.number, 0),
    //items: types.maybe(Items)
    //items: types.array(Items)
  })
  .views(self => ({
    get person() {
      //if (self.title !== null || self.firstName !== null || self.surName !== null)
      const title = self.title !== null ? self.title : ''
      const titleAfter = self.titleAfter !== null ? self.titleAfter : ''
      const surName = self.surName !== null ? self.surName : ''
      const firstName = self.firstName !== null ? self.firstName : ''

      const personToReturn = `${title}${title !== '' ? ' ' : ''}${firstName}${firstName !== '' ? ' ' : ''}${surName}${titleAfter !== '' ? ' ' : ''}${titleAfter}`
      return self.titleAfter !== null || self.title !== null || self.firstName !== null || self.surName !== null  ? personToReturn : ''
    },
    get deliveryPerson() {
      //if (self.title !== null || self.firstName !== null || self.surName !== null)
      const title = self.deliveryAddress?.title !== null ? self.deliveryAddress?.title : ''
      const titleAfter = self.deliveryAddress?.titleAfter !== null ? self.deliveryAddress?.titleAfter : ''
      const surName = self.deliveryAddress?.surName !== null ? self.deliveryAddress?.surName : ''
      const firstName = self.deliveryAddress?.firstName !== null ? self.deliveryAddress?.firstName : ''

      const personToReturn = `${title}${title !== '' ? ' ' : ''}${firstName}${firstName !== '' ? ' ' : ''}${surName}${titleAfter !== '' ? ' ' : ''}${titleAfter}`
      if (self.deliveryAddress?.titleAfter !== null || self.deliveryAddress?.title !== null || self.deliveryAddress?.firstName !== null || self.deliveryAddress?.surName !== null) 
        {
          if (self.deliveryAddress?.titleAfter !== undefined || self.deliveryAddress?.title !== undefined || self.deliveryAddress?.firstName !== undefined || self.deliveryAddress?.surName !== undefined) 
            {
              return personToReturn
            }
          else { return '' }  
        }
      else { return '' }
    },
    get gdprIsActive() {

      return new Date(self.gdprDate!.replace(' ', 'T')) > new Date() ? true:false
    },
    get gdprIsActiveForUpgrade() {
      let d = new Date();
      let year = d.getFullYear();
      let month = d.getMonth();
      let day = d.getDate();
      let plus = 0
      if (month > 12) plus = 1
      let c = new Date(year + plus, month, day);
    //console.log(c)
      return new Date(self.gdprDate!.replace(' ', 'T')) > c ? true:false
    }
  }))
  .actions(self => ({
    setBillingAddress(newStreet: string | undefined, newHouseNumber: string | undefined, newIdentificationNumber: string | undefined, newPostalCode: string | undefined, newCity: string | undefined) {
        self.billingAddress = {street: newStreet, houseNumber: newHouseNumber, identificationNumber: newIdentificationNumber, postalCode: newPostalCode, city: newCity}
    },
    setDeliveryAddress(newName: string, newTitile: string , newFirstName: string | null, newSurName: string | null, newTitileAfter: string, newStreet: string | null, newHouseNumber: string | null, newIdentificationNumber: string | null, newPostalCode: string | null, newCity: string | null, newPhoneNumber: string | null) {
      self.deliveryAddress = {name: newName, title: newTitile, firstName: newFirstName, surName: newSurName, titleAfter: newTitileAfter,  street: newStreet, houseNumber: newHouseNumber, identificationNumber: newIdentificationNumber, postalCode: newPostalCode, city: newCity, phoneNumber: newPhoneNumber}
    },
    setInformations(newName: string, newTaxIdentificationNumber: string | null, newIsTaxAble: boolean, newTitile: string , newFirstName: string | null, newSurName: string | null, newTitileAfter: string, newCompanyRegistrationNumber: string) {
      const person = `${newSurName} ${newFirstName}${newTitile.trim() !== '' ? ', ' : '' }${newTitile.trim()}${newTitileAfter.trim() !== '' ? ', ' : '' }${newTitileAfter.trim()}`
      const personClassic = `${newTitile.trim()}${newTitile.trim() !== '' ? ' ' : '' }${newFirstName} ${newSurName}${newTitileAfter.trim() !== '' ? ' ' : '' }${newTitileAfter.trim()}`
      if (newName.trim() === '') {
        self.businessName = person.trim()
      }
      else {
        self.businessName = `${newName}${personClassic.trim() !== '' ? ', ' : ''}${personClassic.trim()}`
      }
      self.name = newName
      self.taxIdentificationNumber = newTaxIdentificationNumber
      self.isTaxAble = newIsTaxAble
      self.title = newTitile
      self.firstName = newFirstName
      self.surName = newSurName
      self.titleAfter = newTitileAfter
      self.companyRegistrationNumber = newCompanyRegistrationNumber
    },
    setContact(id: string, newType: string, newValue: string, newNote: string, newIsBilling:boolean) {
      const index = self.contacts.findIndex(contact => contact.id === id)
      self.contacts[index].type = newType
      self.contacts[index].value = newValue
      self.contacts[index].note = newNote
      self.contacts[index].isBilling = newIsBilling
    },
    deleteDeliveryAddress() {
      self.deliveryAddress = null
    },
    deleteContact(id: string) {
      const index = self.contacts.findIndex(contact => contact.id === id)
      self.contacts.splice(index,1)
      
    }

  }))
