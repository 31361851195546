import { types } from 'mobx-state-tree'


export const CustomerList = types
  .model({
    id: types.optional(types.string, ''),
    accountNumber: types.maybeNull(types.string),
    businessName: types.optional(types.string, ''),
    companyRegistrationNumber: types.optional(types.string, ''),
    taxIdentificationNumber: types.optional(types.string, ''),
    isTaxAble: types.optional(types.boolean, false),
    isActive: types.optional(types.boolean, false),

  })
  .views(self => ({
    get customersListItems() {
      return null //self.items
    }
  }))
  .actions(self => ({
    

  }))
