import { types } from 'mobx-state-tree'


export const OnlineData = types
  .model({
    
    actionUid: types.maybeNull(types.string),
    soutId: types.maybeNull(types.number),
    competitionName: types.maybeNull(types.string),
    actualRound: types.maybeNull(types.number),
    roundId: types.maybeNull(types.number),
    roundName: types.maybeNull(types.string),
    roundState: types.maybeNull(types.number),
    roundType: types.maybeNull(types.number),
    danceId: types.maybeNull(types.number),
    danceName: types.maybeNull(types.string),
    danceState: types.maybeNull(types.number),
    startNo: types.maybeNull(types.number),
    startNo2: types.maybeNull(types.string),
    groupNumber: types.maybeNull(types.number),
    toNextRound: types.maybeNull(types.string),
    placeFrom: types.maybeNull(types.number),
    placeTo: types.maybeNull(types.number),
    total: types.maybeNull(types.number),
    dancerUid: types.maybeNull(types.string),
    celebrated: types.maybeNull(types.number),
    heat: types.maybeNull(types.number),
    
  })
  .views(self => ({
    get groupNo() {
      return self.groupNumber === 0 ? 1 : self.groupNumber
    }
  }))
  .actions(self => ({
    

  }))
