import { getUser, getToken, errorEvaluation } from '../Utils/Common';
//import React, { useContext } from 'react'
//import { connectReduxDevtools } from 'mst-middlewares'
import { LoggedUser } from './loggedUser.store'

import { CompetitionsList } from './competitionsList.store'
import { CompetitionsListSchedule } from './competitionsList.schedule.store'
import { CompetitionAppList } from './competitionAppList.store'
import { CompetitionAppListByPeople } from './competitionAppListByPeople.store'
import { Application } from './application.store'

import { CompetitionItemsWithCountsList } from './competitionItemsWithCounts.List.store'
import { CompetitionAppListWithStartCountsList } from './competitionAppListWithStartCounts.List.store'

import { DancerList } from './dancerList.store'
import { DancerListCAL } from './dancerListCAL.store'
import { MembersSoloDuo } from './membersSoloDuo.store'
import { MembersProAm } from './membersProAm.store'

import { MembersSUTimport } from './membersSUTimport.store'

import { BankAccountTransfersCAL } from './bankAccountTransfersCAL.store';
import { BankAccountTransfersCPADU } from './bankAccountTransfersCPADU.store';


import { OnlineActions } from './online.actions.store'
import { OnlineDancers } from './online.dancers.store'
import { OnlineData } from './online.data.store'

import { Countries } from './countries.store'

import { DialsClasses } from './dialsClasses.store'
import { DialsStyles } from './dialsStyles.store'
import { DialsLevels } from './dialsLevels.store'
import { DialsCompetitionTypes } from './dialsCompetitionTypes.store'
import { DialsCompetitionVariants } from './dialsCompetitionVariants.store'
import { DialsAgeCategories } from './dialsAgeCategories.store'
import { DialsCompetitions } from './dialsCompetitions.store'

import { CustomerList } from './customerList.store'
import { CustomerOpened } from './customerOpened.store'
import { CustomerUsers } from './customerUsers.store'
import { CustomerOfferCount } from './customerOffersCount.store'
import { CustomerOpenedCategories } from './customerOpenedCategories.store'
import { CustomerOpenedLicenses } from './customerOpenedLicenses.store'
import { CustomerOpenedServices } from './customerOpenedServices.store'
import { CustomerOpenedDocuments } from './customerOpenedDocuments.store'
import { CustomerOpenedEmailsSent } from './customerOpenedEmailsSent.store'
import { CustomerOpenedHistory } from './customerOpenedHistory.store'
import { Invites } from './invites.store'
import { OverviewsCustomers } from './overviewsCustomers.store'
import { DatafiltersCustomers } from './datafiltersCustomers.store'
import { OverviewsOffers } from './overviewsOffers.store'
import { DatafiltersOffers } from './datafiltersOffers.store'
import { Templates } from './templates.store'
import { LicensesTransferOldCustomer } from './licensesTransferOldCustomer.store'
import { LicensesTransferNewCustomer } from './licensesTransferNewCustomer.store'
import { LicensesTransferOldCustomerLicenses } from './licensesTransferOldCustomerLicenses.store'

import { Order } from './order.store'
import { types, Instance, flow, applySnapshot } from 'mobx-state-tree'
import { sleep } from '../helpers/sleep'

//import { logout } from '../authProvider'

import * as Constants from '../Constants/Constants'
import axios from 'axios';

import moment from 'moment'

/*
function isNetworkError(err:any) {
  return !!err.isAxiosError && !err.response;
}
*/

export const RootStore = types
  .model({
    competitionsList: types.array(CompetitionsList),
    competitionsListSchedule: types.array(CompetitionsListSchedule),
    competitionAppList: types.array(CompetitionAppList),
    competitionAppListByPeople: types.array(CompetitionAppListByPeople),
    application: types.array(Application),
    dancerList: types.array(DancerList),
    dancerListCAL: types.array(DancerListCAL),
    membersSoloDuo: types.array(MembersSoloDuo),
    membersSUTimport: types.array(MembersSUTimport),

    membersProAm: types.array(MembersProAm),

    bankAccountTansfersCAL: types.array(BankAccountTransfersCAL),
    bankAccountTansfersCPADU: types.array(BankAccountTransfersCPADU),

    competitionItemsWithCountsList: types.array(CompetitionItemsWithCountsList),
    competitionAppListWithStartCountsList: types.array(CompetitionAppListWithStartCountsList),
    
    loggedUsers: types.array(LoggedUser),

    dialsClasses: types.array(DialsClasses),
    dialsStyles: types.array(DialsStyles),
    dialsLevels: types.array(DialsLevels),
    dialsCompetitionTypes: types.array(DialsCompetitionTypes),
    dialsCompetitionVariants: types.array(DialsCompetitionVariants),
    dialsAgeCategories: types.array(DialsAgeCategories),
    dialsCompetitions: types.array(DialsCompetitions),

    onlineActions: types.array(OnlineActions),
    onlineDancers: types.array(OnlineDancers),
    onlineData: types.array(OnlineData),

    countries: types.array(Countries),


    order: types.array(Order),
    customerList: types.array(CustomerList),
    customerOpened: types.array(CustomerOpened),
    customerOffersCount: types.array(CustomerOfferCount),
    customerOpenedCategories: types.array(CustomerOpenedCategories),
    customerOpenedLicenses: types.array(CustomerOpenedLicenses),
    customerOpenedServices: types.array(CustomerOpenedServices),
    customerOpenedDocuments: types.array(CustomerOpenedDocuments),
    customerOpenedEmailsSent: types.array(CustomerOpenedEmailsSent),
    customerOpenedHistory: types.array(CustomerOpenedHistory),
    customerUsers: types.array(CustomerUsers),   
    invite: types.array(Invites),
    overviewsCustomer: types.array(OverviewsCustomers),
    datafiltersCustomers: types.array(DatafiltersCustomers),
    overviewsOffer: types.array(OverviewsOffers),
    datafiltersOffers: types.array(DatafiltersOffers),
    templates: types.array(Templates),
    licensesTransferOldCustomer: types.array(LicensesTransferOldCustomer),
    licensesTransferNewCustomer: types.array(LicensesTransferNewCustomer),
    licensesTransferOldCustomerLicenses: types.array(LicensesTransferOldCustomerLicenses),
  })
  .views(self => ({
    getCompetitionById(id: string) {
      return self.competitionsList.find(competition => competition.idCompetitions === id)
    },
    getDancerById(id: string) {
      return self.dancerList.find(dancer => dancer.idMembers === id)
    },
    getDancerCALById(id: string) {
      return self.dancerListCAL.find(dancer => dancer.id === id)
    },
    getClassById(id: string) {
      return self.dialsClasses.find(dialsClass => dialsClass.idClasses === id)
    },
    getStyleById(id: string) {
      return self.dialsStyles.find(dialsStyle => dialsStyle.idStyles === id)
    },
    getLevelById(id: string) {
      return self.dialsLevels.find(dialsLevel => dialsLevel.idLevels === id)
    },
    getCompetitionTypeById(id: string) {
      return self.dialsCompetitionTypes.find(dialsCompetitionType => dialsCompetitionType.idCompetitionTypes === id)
    },
    getCompetitionVariantById(id: string) {
      return self.dialsCompetitionVariants.find(dialsCompetitionVariant => dialsCompetitionVariant.idCompetitionVariants === id)
    },
    getAgeCategoryById(id: string) {
      return self.dialsAgeCategories.find(dialsAgeCategory => dialsAgeCategory.idAgeCategories === id)
    },
    getDialsCompetitionById(id: string) {
      return self.dialsCompetitions.find(dialsCompetitions => dialsCompetitions.idDialsCompetitions === id)
    },
    getCompetitionAppListById(id: string) {
      return self.competitionAppList.find(competition => competition.idCompetitions === id)
    },
    getCompetitionAppListByPeopleById(id: string) {
      return self.competitionAppListByPeople.find(competition => competition.idCompetitions === id)
    },
    getApplicationById(id: string) {
      return self.application.find(application => application.idApplications === id)
    },
    getMembersAllByFilter(value: string) {
      return self.membersSoloDuo.filter(cl => cl.firstName?.toLowerCase().includes(value!.toLowerCase()) || cl.surName?.toLowerCase().includes(value!.toLowerCase()))
    },
    getMembersProAmAllByFilter(value: string, status: string) {
      return self.membersProAm.filter(cl => cl.status === status && (cl.firstName?.toLowerCase().includes(value!.toLowerCase()) || cl.surName?.toLowerCase().includes(value!.toLowerCase())))
    },
    getMembersProAmAllByFilter2(value: string) {
      return self.membersProAm.filter(cl =>  (cl.firstName?.toLowerCase().includes(value!.toLowerCase()) || cl.surName?.toLowerCase().includes(value!.toLowerCase())))
    },
    getOnlineFirstCompetitionBySoutId(soutId:number) {
      return self.onlineData.find(data => data.soutId === soutId)
    }

  }))
  .actions(self => ({
    fetchBankAccountTransfersCALList: flow(function* fetchProjects(userId:string, userPassword:string) {
      try {
        //self.application.clear()
        const ret = yield axios.get(Constants.URL_BANK_ACCOUNT_TRANSFERS_LIST_CAL,{params: {
          userId,
          userPassword
        }}).then(response => {
         
          return response.data.bankAccountTransfrersList
          
        }).catch(error => {
          
        });
        
        applySnapshot(self.bankAccountTansfersCAL,ret) 
        
      } catch (error) {
        console.error('Failed to fetch BT', error)
      }
    }),

    fetchBankAccountTransfersCPADUList: flow(function* fetchProjects(userId:string, userPassword:string) {
      try {
        //self.application.clear()
        const ret = yield axios.get(Constants.URL_BANK_ACCOUNT_TRANSFERS_LIST_CPADU,{params: {
          userId,
          userPassword
        }}).then(response => {
         
          return response.data.bankAccountTransfrersList
          
        }).catch(error => {
          
        });
        
        applySnapshot(self.bankAccountTansfersCPADU,ret) 
        
      } catch (error) {
        console.error('Failed to fetch BT', error)
      }
    }),


    fetchCountries: flow(function* fetchProjects() {
      try {
        self.countries.clear()
        const ret = yield axios.get('countries.json?timestamp='+moment(new Date()).format('YYYYMMDDHHmmssSS')).then(response => {
         
          return response.data
          
        }).catch(error => {
          
        });
        
        applySnapshot(self.countries,ret) 
        
      } catch (error) {
        console.error('Failed to fetch competitions', error)
      }
    }), 


    fetchOnlineActions: flow(function* fetchProjects() {
      try {
        self.onlineActions.clear()
        const ret = yield axios.get(Constants.URL_ONLINE_ACTIONS).then(response => {
         
          return response.data.actionsList
          
        }).catch(error => {
          
        });
        
        applySnapshot(self.onlineActions,ret) 
        
      } catch (error) {
        console.error('Failed to fetch competitions', error)
      }
    }),   

    fetchOnlineDancers: flow(function* fetchProjects(actionUid:string) {
      try {
        self.onlineDancers.clear()
        const ret = yield axios.get(Constants.URL_ONLINE_DANCERS,{params: {
          actionUid
        }}).then(response => {
         
          return response.data.dancersList
          
        }).catch(error => {
          
        });
        
        applySnapshot(self.onlineDancers,ret) 
        
      } catch (error) {
        console.error('Failed to fetch competition', error)
      }
    }),
    
    fetchOnlineData: flow(function* fetchProjects(id:string) {
      try {
        //self.onlineData.clear()
        const ret = yield axios.get(Constants.URL_ONLINE_DATA,{params: {
          id
        }}).then(response => {
         
          return response.data.data
          
        }).catch(error => {
          
        });
        
        if (Object.keys(ret).length === 0) {
          self.onlineData.clear()
        }
        else {
          applySnapshot(self.onlineData,ret) 
        }

      } catch (error) {
        console.error('Failed to fetch competition', error)
      }
    }),

    clearOnlineData: flow(function* fetchProjects() {
      try {
        self.onlineData.clear()
        yield sleep(10)
        
      } catch (error) {
        console.error('Failed to clear data', error)
      }
    }),

    fetchCompetitions: flow(function* fetchProjects(id:string) {
      try {
        self.competitionsList.clear()
        const ret = yield axios.get(Constants.URL_COMPETITION,{params: {
          id
        }}).then(response => {
         
          return response.data
          
        }).catch(error => {
          
        });
        
        applySnapshot(self.competitionsList,[ret]) 
        
      } catch (error) {
        console.error('Failed to fetch competition', error)
      }
    }),

    fetchCompetitionsSchedule: flow(function* fetchProjects() {
      try {
        self.competitionsListSchedule.clear()
        const ret = yield axios.get(Constants.URL_COMPETITION_SHEDULE).then(response => {
         
          return response.data.competitionsList
          
        }).catch(error => {
          
        });
        
        applySnapshot(self.competitionsListSchedule,ret) 
        
      } catch (error) {
        console.error('Failed to fetch competitions', error)
      }
    }),    

    fetchCompetitionAppList: flow(function* fetchProjects(id:string) {
      try {
        self.competitionsList.clear()
        const ret = yield axios.get(Constants.URL_COMPETITION_APPLICATION_LIST,{params: {
          id
        }}).then(response => {
         
          return response.data
          
        }).catch(error => {
          
        });
        
        applySnapshot(self.competitionAppList,[ret]) 
        
      } catch (error) {
        console.error('Failed to fetch competitionAppList', error)
      }
    }),   

    fetchCompetitionItemssWithCountsList: flow(function* fetchProjects(id:string) {
      try {
        self.competitionItemsWithCountsList.clear()
        const ret = yield axios.get(Constants.URL_COMPETITION_ITEMS_WITH_COUNTS_LIST,{params: {
          id
        }}).then(response => {
         
          return response.data.competitionItemsList
          
        }).catch(error => {
          
        });
        
        applySnapshot(self.competitionItemsWithCountsList,ret) 
        
      } catch (error) {
        console.error('Failed to fetch competitionItemsWithCountsList', error)
      }
    }), 

    fetchCompetitionAppListWithStartCountsList: flow(function* fetchProjects(id:string) {
      try {
        self.competitionAppListWithStartCountsList.clear()
        const ret = yield axios.get(Constants.URL_COMPETITION_APP_BY_NAMES_WITH_STARTCOUNTS_LIST,{params: {
          id
        }}).then(response => {
         
          return response.data.appByNamesWithCounts
          
        }).catch(error => {
          
        });
        
        applySnapshot(self.competitionAppListWithStartCountsList,ret) 
        
      } catch (error) {
        console.error('Failed to fetch competitionAppListWithStartCountsList', error)
      }
    }), 
    
    fetchCompetitionAppListByPeople: flow(function* fetchProjects(id:string, password:string) {
      try {
        self.competitionsList.clear()
        const ret = yield axios.get(Constants.URL_COMPETITION_APPLICATION_LIST_BY_PEOPLE,{params: {
          id,password
        }}).then(response => {
         
          localStorage.setItem(id, 'admin')
          return response.data
          
        }).catch(error => {
          
        });
        
        applySnapshot(self.competitionAppListByPeople,[ret]) 
        
      } catch (error) {
        console.error('Failed to fetch competitionAppListByPeople', error)
      }
    }),  

    fetchApplication: flow(function* fetchProjects(id:string, idCompetitions:string, secureCode:string ) {
      try {
        //self.application.clear()
        const ret = yield axios.get(Constants.URL_APPLICATION_READ_ONE,{params: {
          id,
          idCompetitions,
          secureCode
        }}).then(response => {
         
          return response.data
          
        }).catch(error => {
          
        });
        
        applySnapshot(self.application,[ret]) 
        
      } catch (error) {
        console.error('Failed to fetch application', error)
      }
    }),

    fetchDancerList: flow(function* fetchProjects(userId:string, userPassword:string) {
      try {
        //self.application.clear()
        const ret = yield axios.get(Constants.URL_DANCER_LIST,{params: {
          userId,
          userPassword
        }}).then(response => {
         
          return response.data.dancersList
          
        }).catch(error => {
          
        });
        
        applySnapshot(self.dancerList,ret) 
        
      } catch (error) {
        console.error('Failed to fetch dancers', error)
      }
    }),

    fetchDancerListCAL: flow(function* fetchProjects(userId:string, userPassword:string) {
      try {
        //self.application.clear()
        const ret = yield axios.get(Constants.URL_DANCER_CAL_LIST,{params: {
          userId,
          userPassword
        }}).then(response => {
         
          return response.data.dancersList
          
        }).catch(error => {
          
        });
        
        applySnapshot(self.dancerListCAL,ret) 
        
      } catch (error) {
        console.error('Failed to fetch dancersCAL', error)
      }
    }),

    fetchMembersSoloDuo: flow(function* fetchProjects() {
      try {
        //self.application.clear()
        const ret = yield axios.get(Constants.URL_MEMBERS_SOLO_DUO,{params: {
          
        }}).then(response => {
         
          return response.data.membersSoloDuo
          
        }).catch(error => {
          
        });
        
        applySnapshot(self.membersSoloDuo,ret) 
        
      } catch (error) {
        console.error('Failed to fetch members', error)
      }
    }),

    fetchMembersProAm: flow(function* fetchProjects() {
      try {
        //self.application.clear()
        const ret = yield axios.get(Constants.URL_MEMBERS_PROAM,{params: {
          
        }}).then(response => {
         
          return response.data.membersProAm
          
        }).catch(error => {
          
        });
        
        applySnapshot(self.membersProAm,ret) 
        
      } catch (error) {
        console.error('Failed to fetch membersProAm', error)
      }
    }),

    fetchMembersSUTimport: flow(function* fetchProjects() {
      try {
        //self.application.clear()
        const ret = yield axios.get(Constants.URL_MEMBERS_SUT_IMPORT,{params: {
          
        }}).then(response => {
         
          return response.data.membersSoloDuo
          
        }).catch(error => {
          
        });
        
        applySnapshot(self.membersSUTimport,ret) 
        
      } catch (error) {
        console.error('Failed to fetch members', error)
      }
    }),

    fetchDialsClasses: flow(function* fetchProjects() {
      try {
        //self.application.clear()
        const ret = yield axios.get(Constants.URL_DIALS_CLASSES_LIST).then(response => {
         
          return response.data.classesList
          
        }).catch(error => {
          
        });
        
        applySnapshot(self.dialsClasses,ret) 
        
      } catch (error) {
        console.error('Failed to fetch classes', error)
      }
    }),

    fetchDialsStyles: flow(function* fetchProjects() {
      try {
        //self.application.clear()
        const ret = yield axios.get(Constants.URL_DIALS_STYLES_LIST).then(response => {
         
          return response.data.stylesList
          
        }).catch(error => {
          
        });
        
        applySnapshot(self.dialsStyles,ret) 
        
      } catch (error) {
        console.error('Failed to fetch styles', error)
      }
    }),

    fetchDialsLevels: flow(function* fetchProjects() {
      try {
        //self.application.clear()
        const ret = yield axios.get(Constants.URL_DIALS_LEVELS_LIST).then(response => {
         
          return response.data.levelsList
          
        }).catch(error => {
          
        });
        
        applySnapshot(self.dialsLevels,ret) 
        
      } catch (error) {
        console.error('Failed to fetch levels', error)
      }
    }),

    fetchDialsCompetitionTypes: flow(function* fetchProjects() {
      try {
        //self.application.clear()
        const ret = yield axios.get(Constants.URL_DIALS_COMPETITION_TYPES_LIST).then(response => {
         
          return response.data.competitionTypesList
          
        }).catch(error => {
          
        });
        
        applySnapshot(self.dialsCompetitionTypes,ret) 
        
      } catch (error) {
        console.error('Failed to fetch competitionTypes', error)
      }
    }),

    fetchDialsCompetitionVariants: flow(function* fetchProjects() {
      try {
        //self.application.clear()
        const ret = yield axios.get(Constants.URL_DIALS_COMPETITION_VARIANTS_LIST).then(response => {
         
          return response.data.competitionVariantsList
          
        }).catch(error => {
          
        });
        
        applySnapshot(self.dialsCompetitionVariants,ret) 
        
      } catch (error) {
        console.error('Failed to fetch competitionVariants', error)
      }
    }),

    fetchDialsAgeCategories: flow(function* fetchProjects() {
      try {
        //self.application.clear()
        const ret = yield axios.get(Constants.URL_DIALS_AGE_CATEGORIES_LIST).then(response => {
         
          return response.data.ageCategoriesList
          
        }).catch(error => {
          
        });
        
        applySnapshot(self.dialsAgeCategories,ret) 
        
      } catch (error) {
        console.error('Failed to fetch ageCategories', error)
      }
    }),

    fetchDialsCompetitions: flow(function* fetchProjects() {
      try {
        //self.application.clear()
        const ret = yield axios.get(Constants.URL_DIALS_COMPETITIONS_LIST).then(response => {
         
          return response.data.dialsCompetitionsList
          
        }).catch(error => {
          
        });
        
        applySnapshot(self.dialsCompetitions,ret) 
        
      } catch (error) {
        console.error('Failed to fetch dialsCompetitions', error)
      }
    }),

    fetchLoggedUsersAdj: flow(function* fetchProjects(PIN:string) {
      try {
        // ... get users from server emulation
        yield sleep(300)
        
        const cl = yield axios.post(Constants.URL_LOGIN,{ PIN: PIN
          }).then(response => {
            //console.log(response.data)
            const aSurName = response.data.PRIJMENI !== null ? response.data.PRIJMENI : ''
            const aFirstName = response.data.JMENO !== null ? response.data.JMENO : ''
            
            localStorage.setItem('userNameMenu', aFirstName+' '+aSurName)

            //console.log(aId)

          return  [response.data] 
          
        }).catch(error => {
          errorEvaluation(error)
        });
        applySnapshot(self.loggedUsers,cl) 

      } catch (error) {
        console.error('Nepodařilo se načíst aktuálně přihlášeného uživatele. Může dojít k odhlášení!', error)
        //sessionStorage.setItem('automaticLogout', '2')        
        //removeUserSession()
        //logout()
      }
    }),

    fetchLoggedUsers: flow(function* fetchProjects(loginData:string, passwordData:string) {
      try {
        // ... get users from server emulation
        yield sleep(300)
        
        const cl = yield axios.post(Constants.URL_LOGIN,{ login: loginData, password:passwordData
          }).then(response => {
            //console.log(response.data)
            
            localStorage.setItem('userNameMenu', response.data.fullName)

            //console.log(aId)

          return  [response.data] 
          
        }).catch(error => {
          errorEvaluation(error)
        });
        applySnapshot(self.loggedUsers,cl) 

      } catch (error) {
        console.error('Nepodařilo se načíst aktuálně přihlášeného uživatele. Může dojít k odhlášení!', error)
        //sessionStorage.setItem('automaticLogout', '2')        
        //removeUserSession()
        //logout()
      }
    }),
    
    fetchOrders: flow(function* fetchProjects() {
      try {
        
        const ret = yield axios.get(Constants.ORDER_URL).then(response => {
         
          return response.data
          
        }).catch(error => {
          
        });
        
        self.order = ret
        
      } catch (error) {
        console.error('Failed to fetch users', error)
      }
    }),
    fetchCustomerList: flow(function* fetchProjects(searchValue:string,searchType:string, pageNumber:number) {
      try {

        yield sleep(300)
      
        //console.log(searchValue)

        const filter = searchValue === '' || searchValue === null ? '' : searchType+'~'+searchValue
        const page = pageNumber
        const pagesize = 10

        //const params = search.concat(paging)

        const accessToken = yield getToken()

        const cl = yield axios.get(Constants.URL_CUSTOMER_LIST,{
          headers: {
            'Authorization': 'Bearer ' + accessToken
          },params: {
            filter,
            page,
            pagesize
          }  }).then(response => {

          if (response.data.totalCount > 5) {
            localStorage.setItem('customersTooMany','1')
          }
          else {localStorage.setItem('customersTooMany','0')}
          localStorage.setItem('customersTotalCount',response.data.totalCount)
          localStorage.setItem('customersFirstId',response.data.items[0].id)
          //console.log(response.data.items)
          return response.data.items
          
        }).catch(error => {
          errorEvaluation(error)
        });
        if (localStorage.getItem('customersTotalCount') === '0') {
          self.customerList.clear()
        }
        else {
          applySnapshot(self.customerList,cl) 
        }
        
          
    } catch (error) {
      console.error('Nepodařilo se načíst seznam zákazníků. Může dojít k odhlášení!', error)
      /*sessionStorage.setItem('automaticLogout', '2')        
      removeUserSession()
      logout()*/
    } 
    }),

    fetchCustomerOpened: flow(function* fetchProjects() {
      try {

       yield sleep(300)
      
       const accessToken = yield getToken()

        const cl = yield axios.get(Constants.URL_CUSTOMER_LIST+'/'+localStorage.getItem('openedCustomerId'),{
          headers: {
            'Authorization': 'Bearer ' + accessToken
          }}).then(response => {
          
          return response.data
          
        }).catch(error => {
          errorEvaluation(error)
        });
        //console.log(cl)
        applySnapshot(self.customerOpened,[cl]) 
          
    } catch (error) {
      console.error('Nepodařilo se načíst aktuálně otevřeného zákazníka. Může dojít k odhlášení!', error)
      /*sessionStorage.setItem('automaticLogout', '2')        
      removeUserSession()
      logout()*/
    } 
    }),

    
    deleteCustomerOpenedDocuments: flow(function* fetchProjects() {
      try {
        yield sleep(100)
        self.customerOpenedDocuments.clear()
                
      } catch (error) {
        console.error('Failed to clear customerOpenedDocuments', error)
      }
    }),     

    deleteCustomerOpened: flow(function* fetchProjects() {
      try {
        yield sleep(100)
        self.customerOpened.clear()
                
      } catch (error) {
        console.error('Failed to clear customerOpened', error)
      }
    }),    
    deleteCustomerOpenedLicenses: flow(function* fetchProjects() {
      try {
        yield sleep(100)
        self.customerOpenedLicenses.clear()
                
      } catch (error) {
        console.error('Failed to clear customerOpenedLicenses', error)
      }
    }), 
    deleteCustomerList: flow(function* fetchProjects() {
      try {
        yield sleep(100)
        self.customerList.clear()
                
      } catch (error) {
        console.error('Failed to delete customerList', error)
      }
    }),

    deleteLoggedUser: flow(function* fetchProjects() {
      try {
        yield sleep(100)
        self.loggedUsers.clear()
                
      } catch (error) {
        console.error('Failed to delete loggedUsers', error)
      }
    }),

    deleteInvites: flow(function* fetchProjects() {
      try {
        yield sleep(100)
        self.invite.clear()
                
      } catch (error) {
        console.error('Failed to delete invites', error)
      }
    }),

    

    fetchInvite: flow(function* fetchProjects() {
      try {

       //yield authFetch(Constants.URL_INVITES, {method: 'GET',headers: {'Content-Type': 'application/json'}})
       yield sleep(300)
      
       const accessToken = yield getToken()
      
        const cl = yield axios.get(Constants.URL_INVITES,{
          headers: {
            'Authorization': 'Bearer ' + accessToken
          }

        
        }).then(response => {

          localStorage.setItem('invitesTotalCount',response.data.count)
          //console.log(response.data)
          return response.data.items
        }).catch(error => {
          errorEvaluation(error)
        });

        applySnapshot(self.invite,cl) 
          
    } catch (error) {
      console.error('Nepodařilo se načíst pozvánky. Dojde k odhlášení!', error)
      /*sessionStorage.setItem('automaticLogout', '2')        
      removeUserSession()
      logout()*/
    }

      
    }),


    fetchCustomerUsers: flow(function* fetchProjects() {
      try {
       yield sleep(300)
      
       const accessToken = yield getToken()

        const cl = yield axios.get(Constants.URL_CUSTOMER_USERS+localStorage.getItem('openedCustomerId')+'/users',{
          headers: {
            'Authorization': 'Bearer ' + accessToken
          }
        }).then(response => {
          return response.data.items
        }).catch(error => {
          errorEvaluation(error)
        });

        applySnapshot(self.customerUsers,cl) 
          
    } catch (error) {
      console.error('Nepodařilo se načíst uživatele zákazníka. Může dojít k odhlášení!', error)
      /*sessionStorage.setItem('automaticLogout', '2')        
      removeUserSession()
      logout()*/
    }
      
    }),

    fetchCustomerOfferCount: flow(function* fetchProjects() {
      try {
       yield sleep(300)
      
       const accessToken = yield getToken()

       if (localStorage.getItem('openedCustomerId') !== null) {

        const cl = yield axios.get(Constants.URL_CUSTOMER_OFFER_COUNT+localStorage.getItem('openedCustomerId')+'/offers/state-info',{
          headers: {
            'Authorization': 'Bearer ' + accessToken
          }
        }).then(response => {
          return response.data
        }).catch(error => {
          errorEvaluation(error)
        });

        applySnapshot(self.customerOffersCount,cl) 

      }
          
    } catch (error) {
      console.error('Nepodařilo se načíst počty objednávek. Může dojít k odhlášení!', error)
      /*sessionStorage.setItem('automaticLogout', '2')        
      removeUserSession()
      logout()*/
    }
      
    }),

    deleteCustomerOfferCount: flow(function* fetchProjects() {
      try {
       yield sleep(300)
      
       self.customerOffersCount.clear()
          
    } catch (error) {
      console.error('Nepodařilo se načíst počty objednávek. Může dojít k odhlášení!', error)
      /*sessionStorage.setItem('automaticLogout', '2')        
      removeUserSession()
      logout()*/
    }
      
    }),

    fetchOverviewsCustomers: flow(function* fetchProjects() {
      try {
       yield sleep(300)
      
       const accessToken = yield getToken()

        const cl = yield axios.get(Constants.URL_OVERVIEWS_CUSTOMERS,{
          headers: {
            'Authorization': 'Bearer ' + accessToken
          }
        }).then(response => {
          return response.data.countItems
        }).catch(error => {
          errorEvaluation(error)
        });

        applySnapshot(self.overviewsCustomer,cl) 
          
    } catch (error) {
      console.error('Nepodařilo se načíst seznam přehledů!', error)
      /*sessionStorage.setItem('automaticLogout', '2')        
      removeUserSession()
      logout()*/
    }
      
    }),
    
    
    fetchDatafiltersCustomers: flow(function* fetchProjects(filterId:number,searchValue:string,searchType:string, pageNumber:number) {
      try {

        yield sleep(300)
      
        //console.log(searchValue)

        const filter = searchValue === '' || searchValue === null ? '' : searchType+'~'+searchValue
        const page = pageNumber
        const pagesize = 10

        //const params = search.concat(paging)

        const accessToken = yield getToken()

        const cl = yield axios.get(Constants.URL_DATAFILTERS_CUSTOMERS+filterId.toString(),{
          headers: {
            'Authorization': 'Bearer ' + accessToken
          },params: {
            filter,
            page,
            pagesize
          }  }).then(response => {

          localStorage.setItem('lastDatafilter',filterId.toString())
          localStorage.setItem('lastDatafilterType','customers')
          localStorage.setItem('lastDatafilterTotalCount',response.data.totalCount)
          
          return response.data.items
          
        }).catch(error => {
          errorEvaluation(error)
        });
        //console.log(cl)
        if (localStorage.getItem('lastDatafilterTotalCount') === '0') {
          self.datafiltersCustomers.clear()
        }
        else {
          applySnapshot(self.datafiltersCustomers,cl) 
        }
        
          
    } catch (error) {
      console.error('Failed to fetch datafiltersCustomers', error)
      /*sessionStorage.setItem('automaticLogout', '2')        
      removeUserSession()
      logout()*/
    } 
    }),    

    fetchOverviewsOffers: flow(function* fetchProjects() {
      try {
       yield sleep(300)
      
       const accessToken = yield getToken()

        const cl = yield axios.get(Constants.URL_OVERVIEWS_OFFERS,{
          headers: {
            'Authorization': 'Bearer ' + accessToken
          }
        }).then(response => {
          return response.data.countItems
        }).catch(error => {
          errorEvaluation(error)
        });

        applySnapshot(self.overviewsOffer,cl) 
          
    } catch (error) {
      console.error('Nepodařilo se načíst seznam přehledů!', error)
      /*sessionStorage.setItem('automaticLogout', '2')        
      removeUserSession()
      logout()*/
    }
      
    }),


    fetchDatafiltersOffers: flow(function* fetchProjects(filterId:number,searchValue:string,searchType:string, searchScenario:string, pageNumber:number) {
      try {

        yield sleep(300)
      
        //console.log(searchValue)

        const filter = searchValue === '' || searchValue === null ? '' : searchType+'~'+searchValue
        const upgradeScenarioId = searchScenario === '' || searchScenario === null || searchScenario === undefined ? '' : searchScenario
        const page = pageNumber
        const pagesize = 10

        let paramsToSend:any 

        if (upgradeScenarioId === '') {
          paramsToSend = {
            filter,
            page,
            pagesize
          }
        }
        else {
          paramsToSend = {
            filter,
            page,
            pagesize,
            upgradeScenarioId
          }          
        }

        //const params = search.concat(paging)

        const accessToken = yield getToken()

        const cl = yield axios.get(Constants.URL_DATAFILTERS_OFFERS+filterId.toString(),{
          headers: {
            'Authorization': 'Bearer ' + accessToken
          },params: paramsToSend  }).then(response => {

          localStorage.setItem('lastDatafilter',filterId.toString())
          localStorage.setItem('lastDatafilterType','offers')
          localStorage.setItem('lastDatafilterOfferTotalCount',response.data.totalCount)
          
          return response.data.items
          
        }).catch(error => {
          errorEvaluation(error)
        });
        //console.log(cl)
        if (localStorage.getItem('lastDatafilterOfferTotalCount') === '0') {
          self.datafiltersOffers.clear()
        }
        else {
          applySnapshot(self.datafiltersOffers,cl) 
        }
        
          
    } catch (error) {
      console.error('Failed to fetch datafiltersOffers', error)
      /*sessionStorage.setItem('automaticLogout', '2')        
      removeUserSession()
      logout()*/
    } 
    }),       

    fetchTemplates: flow(function* fetchProjects(templatesSearchValue:string,templatesSearchType:string, templatesSearchIsActive:boolean, templatesSearchWithSystem:boolean, pageSize: number, pageNumber:number) {
      try {
       yield sleep(300)
      
       const filter = templatesSearchValue === '' || templatesSearchValue === null ? '' : templatesSearchType+'~'+templatesSearchValue
       const isActive = templatesSearchIsActive
       const withSystem = templatesSearchWithSystem
       const page = pageNumber
       const pagesize = pageSize

       //const filter = ''
       //const page = 1
       //const pagesize = 1000

       const accessToken = yield getToken()
      
        const cl = yield axios.get(Constants.URL_TEMPLATES,{
          headers: {
            'Authorization': 'Bearer ' + accessToken
          },params: {
            filter,
            isActive,
            withSystem,
            page,
            pagesize
          }

        
        }).then(response => {
          //console.log(response.data)
          localStorage.setItem('lastTemplatesSearchTotalCount',response.data.totalCount)
          return response.data.items
          
        }).catch(error => {
          errorEvaluation(error)
        });

        applySnapshot(self.templates,cl) 
          
    } catch (error) {
      console.error('Failed to fetch templates', error)
      //sessionStorage.setItem('automaticLogout', '2')        
      //removeUserSession()
      //logout()
    }

      
    }),

    fetchTemplateById: flow(function* fetchProjects(id:string) {
      try {
       yield sleep(300)
      
       const accessToken = yield getToken()
      
        const cl = yield axios.get(Constants.URL_TEMPLATES+'/'+id,{
          headers: {
            'Authorization': 'Bearer ' + accessToken
          }

        
        }).then(response => {
          //console.log(response.data)
          return response.data
          
        }).catch(error => {
          errorEvaluation(error)
        });
        //console.log(cl)
        const templatesMerge:any = self.templates.filter(tmpl => tmpl.id !== id).concat(cl)
        //console.log(templatesMerge)
        applySnapshot(self.templates,templatesMerge)
          
    } catch (error) {
      console.error('Failed to fetch templates', error)
      //sessionStorage.setItem('automaticLogout', '2')        
      //removeUserSession()
      //logout()
    }

      
    }),
    
    fetchCustomerOpenedEmailSent: flow(function* fetchProjects(searchValue:string,searchType:string, pageNumber:number) {
      try {

        yield sleep(300)
      
        //console.log(searchValue)

        const filter = searchValue === '' || searchValue === null ? '' : searchType+'~'+searchValue
        const page = pageNumber
        const pagesize = 10

        //const params = search.concat(paging)

        const accessToken = yield getToken()

        const cl = yield axios.get(Constants.URL_CUSTOMER_EMAILS_SENT+localStorage.getItem('openedCustomerId'),{
          headers: {
            'Authorization': 'Bearer ' + accessToken
          },params: {
            filter,
            page,
            pagesize
          }  }).then(response => {

          localStorage.setItem('customerEmailsSentTotalCount',response.data.totalCount)
          
          return response.data.items
          
        }).catch(error => {
          errorEvaluation(error)
        });
        //console.log(cl)
        if (localStorage.getItem('customerEmailsSentTotalCount') === '0') {
          self.customerOpenedEmailsSent.clear()
        }
        else {
          applySnapshot(self.customerOpenedEmailsSent,cl) 
        }
        
          
    } catch (error) {
      console.error('Failed to fetch customerOpenedEmailsSent', error)
      /*sessionStorage.setItem('automaticLogout', '2')        
      removeUserSession()
      logout()*/
    } 
    }),

    fetchCustomerOpenedEmailSentByEmail: flow(function* fetchProjects(email:string, searchValue:string,searchType:string, pageNumber:number) {
      try {

        yield sleep(300)
      
        console.log(searchValue)
        const emailAddress = email
        const filter = searchValue === '' || searchValue === null ? '' : searchType+'~'+searchValue
        const page = pageNumber
        const pagesize = 10

        //const params = search.concat(paging)

        const accessToken = yield getToken()

        const cl = yield axios.get(Constants.URL_ADMIN_EMAILS_SENT,{
          headers: {
            'Authorization': 'Bearer ' + accessToken
          },params: {
            emailAddress,
            filter,
            page,
            pagesize
          }  }).then(response => {

          localStorage.setItem('customerEmailsSentTotalCount',response.data.totalCount)
          
          return response.data.items
          
        }).catch(error => {
          errorEvaluation(error)
        });
        //console.log(cl)
        if (localStorage.getItem('customerEmailsSentTotalCount') === '0') {
          self.customerOpenedEmailsSent.clear()
        }
        else {
          applySnapshot(self.customerOpenedEmailsSent,cl) 
        }
        
          
    } catch (error) {
      console.error('Failed to fetch customerOpenedEmailsSent', error)
      /*sessionStorage.setItem('automaticLogout', '2')        
      removeUserSession()
      logout()*/
    } 
    }),

    deleteCustomerOpenedEmailSent: flow(function* fetchProjects() {
      try {
        yield sleep(100)
        self.customerOpenedEmailsSent.clear()        
      } catch (error) {
        console.error('Failed to delete emails history', error)
      }
    }),
 
    fetchCustomerOpenedHistory: flow(function* fetchProjects(historyType:string, historyParam:string, pageNumber:number) {
      try {

        yield sleep(300)
      
        const page = pageNumber
        const pagesize = 10

        const accessToken = yield getToken()

        let url = ''

        if (historyType === 'customer') { url = 'customers/'+localStorage.getItem('openedCustomerId')! }
        if (historyType === 'customerContact') { url = 'customers/'+localStorage.getItem('openedCustomerId')!+'/contacts/'+historyParam }
        if (historyType === 'offerByScenario') { url = 'offers/upgradeScenarios/'+historyParam+'/customers/'+localStorage.getItem('openedCustomerId')! }
        if (historyType === 'offer') { url = 'offers/'+historyParam }
        if (historyType === 'services') { url = 'customers/'+localStorage.getItem('openedCustomerId')!+'/services' }
        if (historyType === 'categories') { url = 'customers/'+localStorage.getItem('openedCustomerId')!+'/categories' }
        if (historyType === 'license') { url = 'customers/'+localStorage.getItem('openedCustomerId')!+'/licenses' }
        if (historyType === 'template') { url = 'templates/'+historyParam }

        const cl = yield axios.get(Constants.URL_CHANGES+url,{
          headers: {
            'Authorization': 'Bearer ' + accessToken
          },params: {
            page,
            pagesize
          }  }).then(response => {

          localStorage.setItem('customerHistoryTotalCount',response.data.totalCount)
          
          return response.data.items
          
        }).catch(error => {
          errorEvaluation(error)
        });
        //console.log(cl)
        if (localStorage.getItem('customerHistoryTotalCount') === '0') {
          self.customerOpenedHistory.clear()
        }
        else {
          applySnapshot(self.customerOpenedHistory,cl) 
        }
        
          
    } catch (error) {
      console.error('Failed to fetch customerOpenedHistory', error)
      /*sessionStorage.setItem('automaticLogout', '2')        
      removeUserSession()
      logout()*/
    } 
    }),

    deleteCustomerOpenedHistory: flow(function* fetchProjects() {
      try {
        yield sleep(100)
        self.customerOpenedHistory.clear()        
      } catch (error) {
        console.error('Failed to delete history', error)
      }
    }),    

    
    fetchLicensesTransferOldCustomer: flow(function* fetchProjects(accountNumber:string) {
      try {

       yield sleep(300)
       self.licensesTransferOldCustomer.clear()
       const accessToken = yield getToken()
       // eslint-disable-next-line
       const filter = 'AN'+'~'+accountNumber
       const page = 1
       const pagesize = 1

         const searchData = yield axios.get(Constants.URL_CUSTOMER_LIST,{
         headers: {
           'Authorization': 'Bearer ' + accessToken
         },params: {
           filter,
           page,
           pagesize
         }  }).then(response => {


         //else {localStorage.setItem('customersTooMany','0')}
         
         //localStorage.setItem('customersFirstId',response.data.items[0].id)
         
         return response.data.items
         
       }).catch(error => {
         errorEvaluation(error)
       });

        const cl = yield axios.get(Constants.URL_CUSTOMER_LIST+'/'+searchData[0].id,{
          headers: {
            'Authorization': 'Bearer ' + accessToken
          }}).then(response => {
          
          return response.data
          
        }).catch(error => {
          errorEvaluation(error)
        });
        //console.log(cl)
        applySnapshot(self.licensesTransferOldCustomer,[cl]) 
          
    } catch (error) {
      self.licensesTransferOldCustomer.clear()
      console.error('Nepodařilo se načíst zákazníka přenosu. Může dojít k odhlášení!', error)
      /*sessionStorage.setItem('automaticLogout', '2')        
      removeUserSession()
      logout()*/
    } 
    }),

    fetchLicensesTransferOldCustomerLicenses: flow(function* fetchProjects(customerId:string) {
      try {

       yield sleep(300)
       self.licensesTransferOldCustomerLicenses.clear()
       const accessToken = yield getToken()

        const cl = yield axios.get(Constants.URL_TRANSFER_CUSTOMER_LICENSES+customerId+'?status=A&onlyActive=true' ,{
          headers: {
            'Authorization': 'Bearer ' + accessToken
          }}).then(response => {
          //console.log(response.data)
          return response.data
          
        }).catch(error => {
          errorEvaluation(error)
        });
        //console.log(cl)
        applySnapshot(self.licensesTransferOldCustomerLicenses,cl) 
          
    } catch (error) {
      self.licensesTransferOldCustomerLicenses.clear()
      console.error('Nepodařilo se načíst licence zákazníka přenosu. Může dojít k odhlášení!', error)
      /*sessionStorage.setItem('automaticLogout', '2')        
      removeUserSession()
      logout()*/
    } 
    }),

    fetchLicensesTransferNewCustomer: flow(function* fetchProjects(accountNumber:string) {
      try {

       yield sleep(300)
       self.licensesTransferNewCustomer.clear()
       const accessToken = yield getToken()
       // eslint-disable-next-line
       const filter = 'AN'+'~'+accountNumber
       const page = 1
       const pagesize = 1

         const searchData = yield axios.get(Constants.URL_CUSTOMER_LIST,{
         headers: {
           'Authorization': 'Bearer ' + accessToken
         },params: {
           filter,
           page,
           pagesize
         }  }).then(response => {


         //else {localStorage.setItem('customersTooMany','0')}
         
         //localStorage.setItem('customersFirstId',response.data.items[0].id)
         
         return response.data.items
         
       }).catch(error => {
         errorEvaluation(error)
       });

        const cl = yield axios.get(Constants.URL_CUSTOMER_LIST+'/'+searchData[0].id,{
          headers: {
            'Authorization': 'Bearer ' + accessToken
          }}).then(response => {
          
          return response.data
          
        }).catch(error => {
          errorEvaluation(error)
        });
        //console.log(cl)
        applySnapshot(self.licensesTransferNewCustomer,[cl]) 
          
    } catch (error) {
      self.licensesTransferNewCustomer.clear()
      console.error('Nepodařilo se načíst zákazníka přenosu. Může dojít k odhlášení!', error)
      /*sessionStorage.setItem('automaticLogout', '2')        
      removeUserSession()
      logout()*/
    } 
    }),    

    deleteLicencesTransfer: flow(function* fetchProjects() {
      try {
        yield sleep(100)
        self.licensesTransferOldCustomer.clear()
        self.licensesTransferOldCustomerLicenses.clear()
        self.licensesTransferNewCustomer.clear()
                
      } catch (error) {
        console.error('Failed to delete licenses transfer', error)
      }
    }),

    deleteLicencesTransferOldCustomerLicenses: flow(function* fetchProjects() {
      try {
        yield sleep(100)
        self.licensesTransferOldCustomerLicenses.clear()        
      } catch (error) {
        console.error('Failed to delete licenses transfer', error)
      }
    }),

  }))

export type RootStoreType = Instance<typeof RootStore>
export type CustomerListStoreType = Instance<typeof CustomerList>
export type CustomerOfferCountStoreType = Instance<typeof CustomerOfferCount>
export type CustomerOpenedCategoriesStoreType = Instance<typeof CustomerOpenedCategories>
export type CustomerOpenedLicensesStoreType = Instance<typeof CustomerOpenedLicenses>
export type CustomerOpenedServicesStoreType = Instance<typeof CustomerOpenedServices>
export type CustomerOpenedDocumentsStoreType = Instance<typeof CustomerOpenedDocuments>
export type CustomerUsersStoreType = Instance<typeof CustomerUsers>
export type InviteStoreType = Instance<typeof Invites>
export type LoggedUserStoreType = Instance<typeof LoggedUser>
export type OrderStoreType = Instance<typeof Order>
export type OverviewsCustomersStoreType = Instance<typeof OverviewsCustomers>
export type DatafiltersCustomersStoreType = Instance<typeof DatafiltersCustomers>
export type OverviewsOffersStoreType = Instance<typeof OverviewsOffers>
export type TemplatesStoreType = Instance<typeof Templates>
export type LicensesTransferOldCustomerStoreType = Instance<typeof LicensesTransferOldCustomer>
export type LicensesTransferNewCustomerStoreType = Instance<typeof LicensesTransferNewCustomer>
/*
export const setupApp = () => {
  const customerList = CustomerList.create({ id: '', accountNumber: "", businessName: "", companyRegistrationNumber: "", taxIdentificationNumber: "", isTaxAble: false  })
  
  const rootStoreTree = RootStore.create(
    {
      //customerList
    }
  )

  connectReduxDevtools(require('remotedev'), rootStoreTree)

  return rootStoreTree
}

const rootStore = setupApp()

	export const useStore = (): RootStoreType =>
  useContext(React.createContext(rootStore))

  */