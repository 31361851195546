import React, { useState }  from 'react'
import { withRouter, RouteComponentProps } from 'react-router-dom'
import { getToken } from '../../../Utils/Common'

import ErrorAlert from '../../../parts/errorAlert/ErrorAlert.part'

import { Button,  Form, Col, Alert, Modal, Dropdown, DropdownButton, ButtonGroup } from 'react-bootstrap'

import FormControl from 'react-bootstrap/FormControl'
import FormGroup from 'react-bootstrap/FormGroup'
import FormLabel from 'react-bootstrap/FormLabel'

import * as Constants from '../../../Constants/Constants'
import axios from 'axios';

import { confirm } from "../../../Utils/Confirmation"

import { useStore } from '../../../store'
import { observer } from 'mobx-react'

import { Formik } from 'formik'
import * as Yup from 'yup'

import moment from 'moment'

interface MembersUpdateProps {
  id: string
}

const MembersUpdate: React.FC<MembersUpdateProps & RouteComponentProps>  = (props:any) => {
  // Nastavení práv pro editaci


  // Nastavení stavů pro zobrazení
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState(null)
  const [showInfo, setShowInfo] = useState(false)
  const [showForm, setShowForm] = useState('')
  const [show, setShow] = useState(false)
  const [showE, setShowE] = useState(false)

  // Připojení store
  const rootStore = useStore()
  const member = rootStore.getDancerCALById(props.id)
  
  // Otevírání a zavírání modálního okna Změny hesla
  const handleClose = () => {
    setShow(false)
    setShowE(false)
    setError(null)
    sessionStorage.removeItem('autoCloseModal')
  }
  const handleShow = () => {
    sessionStorage.removeItem('autoCloseModal')
    setError(null)
    setShowInfo(false)
    setShowForm('')
    setShow(true)
  }

  // Dotaz před smazáním kontaktu
  const handleOnClickDeleteDancer = () => {
    (async () => {
      if (await confirm("Opravdu chcete sportovce "+member?.surName+" "+member?.firstName+" smazat?",'Ano, smazat', 'Ne', {title: 'Smazání sportovce'})) {
        deleteDancer()
      } else {
      
      }  
    })()
  }

  // Funkce pro smazání kontaktu
  const deleteDancer = () => {
    (async () => {
      // Kontrola přihlášení  
      //const aToken = await getToken()
      if (localStorage.getItem('isLogged') !== 'is') {  
        sessionStorage.setItem('automaticLogout', '1')
        props.history.push('/logout')
      }   
      // Zavolání API   
      await axios.post(Constants.URL_DANCER_DELETE, { idMembers: props.id, userId: rootStore.loggedUsers[0]?.idUsers, userPassword: localStorage.getItem('PIN')! }
        ).then(response => {
          // Po smazání také odebrat ze store
          //customer?.deleteContact(props.id)

          rootStore.fetchDancerList(rootStore.loggedUsers[0]?.idUsers, localStorage.getItem('PIN')!)
        }).catch(error => {
          const errorMessage:any = (<ErrorAlert errorData={error} />)
          setError(errorMessage)
          
          setShowE(true)
        });
  
      })()    
  }

  // Funkce pro změnu kontaktu
  const handleUpdate = (values:any) => {
    setError(null)
    setLoading(true);
    
    // Zpracování požadavku
    (async () => {

      // Kontrola přihlášení  
      //const aToken = await getToken()
      if (localStorage.getItem('isLogged') !== 'is') { 
        sessionStorage.setItem('automaticLogout', '1')
        props.history.push('/logout')
      }
      
      // Zavolání API
      await axios.post(Constants.URL_DANCER_CAL_UPDATE, 
          {
            idMembers: props.id, 
            userId: rootStore.loggedUsers[0]?.idUsers, 
            userPassword: localStorage.getItem('PIN')!, 

            status: values.status,
            soloDuo: ['1','3'].includes(values.status) ? 1:0,
            couples: ['2','3'].includes(values.status) ? 1:0,
            surName: values.surName, 
            firstName: values.firstName, 
            birthdate: values.birthdate, 
            personalIdentificationNumber: values.personalIdentificationNumber, 
            club: values.club, 
            country: values.country, 
            email: values.email, 
            phone: values.phone, 
            street: values.street, 
            houseNumber: values.houseNumber,  
            identificationNumber: values.identificationNumber,
            postalCode: values.postalCode, 
            city: values.city, 
            yesOne: values.yesOne === true ? 1:0, 
            yesTwo: values.yesTwo === true ? 1:0, 
            yesThree: values.yesThree === true ? 1:0, 
            yesFour: values.yesFour === true ? 1:0,
            adultPerson: values.adultPerson,

          }
        ).then(response => {
          
          // Změna dat ve Store
          
          rootStore.fetchDancerListCAL(rootStore.loggedUsers[0]?.idUsers, localStorage.getItem('PIN')!)

          //rootStore.fetchCustomerOpened()  
          // Nastavovačky, co se má v případě, že je to OK, zobrazit
          setLoading(false)
          setShowForm('nodisplay')
          setShowInfo(true)
          sessionStorage.setItem('autoCloseModal','1');

          // Po 2800 ms celé modální okno zavřeme
          setTimeout(() => {
            if (sessionStorage.getItem('autoCloseModal') === '1') {  
              setShow(false)
              setError(null)
              sessionStorage.removeItem('autoCloseModal')
              //setShowInfo(false)
              //setShowForm('')
            }
          }, 2800);

        }).catch(error => {
          setLoading(false)
          const errorMessage:any = (<ErrorAlert errorData={error} />)
          setError(errorMessage)
        });
    })()
  }

  // Schéma validace formuláře
  const schemaUpdate = Yup.object().shape({
    surName: Yup.string()
      .required('Příjmení musí být vyplněno!'),
    /*
    value: Yup.string()
    .required('Hodnota musí být vyplněna!'),*/
  })   
  return (
    <>
      
        <>
          <Button style={{}} variant="primary" size="sm" onClick={handleShow}>
            <i className="fas fa-edit"></i>
          </Button>
          <span style={{marginLeft: '10px'}}></span>
          <Button style={{display:'none'}} variant="danger" size="sm" onClick={() => { handleOnClickDeleteDancer()} }>
            <i className="fas fa-trash-alt"></i>
          </Button>
          
        </>
      
      
      <Modal 
        show={showE} 
        onHide={handleClose} 
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered>
        <Modal.Header closeButton>
          <Modal.Title>Smazání sportovce</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {error}
        </Modal.Body>
      </Modal>

      <Modal 
        show={show} 
        onHide={handleClose} 
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered>
        <Modal.Header closeButton>
          <Modal.Title>Úprava sportovce {member?.id}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Alert variant="success" show={showInfo}>
            <strong>Sportovec byl úspěšně upraven!​</strong>
            
          </Alert>  
          <div className={showForm} >
            <Formik
              validationSchema={schemaUpdate}
              initialValues={{ 
                surName: member?.surName, 
                firstName: member?.firstName, 
                birthdate: moment(member?.birthdate).format("YYYY-MM-DD"),
                personalIdentificationNumber: member?.personalIdentificationNumber,
                club: member?.club,
                country: member?.country,
                email: member?.email,
                phone: member?.phone,
                status: member?.statusNumber,
                street: member?.street,
                houseNumber: member?.houseNumber,
                identificationNumber: member?.identificationNumber,
                postalCode: member?.postalCode,
                city: member?.city,
                yesOne: member?.yesOne,
                yesTwo: member?.yesTwo,
                yesThree: member?.yesThree,
                yesFour: member?.yesFour,
                adultPerson: member?.adultPerson
                
              }}
              onSubmit={(values, { setSubmitting }) => {
                setTimeout(() => {
                  handleUpdate(values)              
                  setSubmitting(false);
                }, 400);
              }}
            >
              {({ 
                handleSubmit,
                handleChange,
                validateField,
                values,
                touched,
                isValid,
                errors,
                isSubmitting }) => (
                <Form onSubmit={handleSubmit}>
                  
                    
                  <FormGroup controlId="status">
                  <Form.Row>
                    <h5 style={{fontWeight: 'bold'}}>Základní údaje</h5>
                  </Form.Row>
                  </FormGroup>  
                  <FormGroup controlId="status">
                    <Form.Row>
                      <FormLabel column lg={3}>Typ členství</FormLabel>
                      <Col>
                        <Form.Control as="select" 
                          name="status"
                          autoComplete="off" 
                          value={values.status}
                          onChange={handleChange}
                          //onChange={async(e) => {await handleChange(e);}}
                          //onBlur={() =>validateField('status')}
                          
                          isValid={touched.status && !errors.status} 
                          isInvalid={!!errors.status}
                          //disabled={selectedMember === '' ? false:true}
                          >
                            <option value="">--- Vyberte ---</option>
                            <option value="1">Sólo & Duo Dance</option>
                            <option value="2">Párové tance</option>
                            <option value="3">Sólo & Duo Dance + Párové tance</option>
                            

                          </Form.Control>
                        <Form.Control.Feedback type="invalid">
                          {errors.status}
                        </Form.Control.Feedback>
                      </Col>
                    </Form.Row>  
                  </FormGroup>             
                    
                  <FormGroup controlId="surName">
                    <Form.Row>
                      <FormLabel column lg={3}>Příjmení</FormLabel>
                      <Col>
                        <FormControl 
                          type="text"
                          name="surName"
                          value={values.surName!}
                          onChange={handleChange}
                          //onChange={async(e) => {await handleChange(e); validateField('surName');}}
                          //onBlur={() =>validateField('surName')}
                          isValid={touched.surName && !errors.surName} 
                          isInvalid={!!errors.surName}
                        />
                        
                        <Form.Control.Feedback type="invalid">
                          {errors.surName}
                        </Form.Control.Feedback>
                      </Col>
                    </Form.Row>  
                  </FormGroup>            

                  <FormGroup controlId="firstName">
                    <Form.Row>
                      <FormLabel column lg={3}>Jméno</FormLabel>
                      <Col>
                        <FormControl 
                          type="text"
                          name="firstName"
                          value={values.firstName!}
                          onChange={handleChange}
                          //onChange={async(e) => {await handleChange(e); }}
                          //onBlur={() =>validateField('firstName')}
                          isValid={touched.firstName && !errors.firstName} 
                          isInvalid={!!errors.firstName}
                        />
                        
                        <Form.Control.Feedback type="invalid">
                          {errors.firstName}
                        </Form.Control.Feedback>
                      </Col>
                    </Form.Row>  
                  </FormGroup>  
                  <FormGroup controlId="birthdate">
                    <Form.Row>
                      <FormLabel column lg={3}>Datum narození</FormLabel>
                      <Col>
                        <FormControl 
                          type="date"
                          name="birthdate"
                          value={values.birthdate!}
                          onChange={handleChange}
                          //onChange={async(e) => {await handleChange(e); await validateField('birthdate'); validateField('adultPerson');}}
                          //onBlur={async () => { validateField('birthdate'); validateField('adultPerson');}}
                          isValid={touched.birthdate && !errors.birthdate} 
                          isInvalid={!!errors.birthdate}
                        />
                        
                        <Form.Control.Feedback type="invalid">
                          {errors.birthdate}
                        </Form.Control.Feedback>
                      </Col>
                    </Form.Row>  
                  </FormGroup>
                  <FormGroup controlId="personalIdentificationNumber">
                    <Form.Row>
                      <FormLabel column lg={3}>Rodné číslo</FormLabel>
                      <Col>
                        <FormControl 
                          type="text"
                          name="personalIdentificationNumber"
                          value={values.personalIdentificationNumber!}
                          onChange={handleChange}
                          //onChange={async(e) => {await handleChange(e); validateField('personalIdentificationNumber');}}
                          //onBlur={() =>validateField('personalIdentificationNumber')}
                          isValid={touched.personalIdentificationNumber && !errors.personalIdentificationNumber} 
                          isInvalid={!!errors.personalIdentificationNumber}
                        />
                        
                        <Form.Control.Feedback type="invalid">
                          {errors.personalIdentificationNumber}
                        </Form.Control.Feedback>
                      </Col>
                    </Form.Row>  
                  </FormGroup>
                  <FormGroup controlId="club">
                    <Form.Row>
                      <FormLabel column lg={3}>Taneční klub</FormLabel>
                      <Col>
                        <FormControl 
                          type="text"
                          name="club"
                          value={values.club!}
                          onChange={handleChange}
                          //onChange={async(e) => {await handleChange(e); validateField('club');}}
                          //onBlur={() =>validateField('club')}
                          isValid={touched.club && !errors.club} 
                          isInvalid={!!errors.club}
                        />
                        
                        <Form.Control.Feedback type="invalid">
                          {errors.club}
                        </Form.Control.Feedback>
                      </Col>
                    </Form.Row>  
                  </FormGroup>

                  <FormGroup controlId="country">
                    <Form.Row>
                      <FormLabel column lg={3}>Stát</FormLabel>
                      <Col>
                        <Form.Control as="select" 
                          name="country"
                          autoComplete="off" 
                          value={values.country!}
                          //onChange={handleChange}
                          onChange={handleChange}
                          //onBlur={() =>validateField('country')}
                          
                          isValid={touched.country && !errors.country} 
                          isInvalid={!!errors.country}
                          //disabled={selectedMember === '' ? false:true}
                          >
                            {rootStore.countries
                            .filter(c => c.priority < 99)
                            .sort((a, b) => {
                              //return a.priority - b.priority;
                              if (a.priority! < b.priority!) {
                                  return -1;
                              }
                              if (a.priority! > b.priority!) {
                                  return 1;
                              }
                              if (a.finalCode < b.finalCode) {
                                return -1;
                              }
                              if (a.finalCode > b.finalCode) {
                                  return 1;
                              } 
                              return 0;
                              })
                            .map(c => (<option value={c.finalCode}>{c.finalCode} - {c.nameCz}</option>))}
                            <option value="X" disabled>-----{'Další státy'}-----</option>
                            {rootStore.countries
                            .filter(c => c.priority > 99)
                            .sort((a, b) => {
                              //return a.priority - b.priority;
                              if (a.priority! < b.priority!) {
                                  return -1;
                              }
                              if (a.priority! > b.priority!) {
                                  return 1;
                              }
                              if (a.finalCode < b.finalCode) {
                                return -1;
                              }
                              if (a.finalCode > b.finalCode) {
                                  return 1;
                              } 
                              return 0;
                              })
                            .map(c => (<option value={c.finalCode}>{c.finalCode} - {c.nameCz}</option>))}
                            
                        </Form.Control>
                        
                        
                        
                        <Form.Control.Feedback type="invalid">
                          {errors.country}
                        </Form.Control.Feedback>
                      </Col>
                    </Form.Row>  
                  </FormGroup>                  
                  <FormGroup controlId="email">
                    <Form.Row>
                      <FormLabel column lg={3}>E-mail</FormLabel>
                      <Col>
                        <FormControl 
                          type="text"
                          name="email"
                          value={values.email!}
                          onChange={handleChange}
                          //onChange={async(e) => {await handleChange(e); validateField('email');}}
                          //onBlur={() =>validateField('email')}
                          isValid={touched.email && !errors.email} 
                          isInvalid={!!errors.email}
                        />
                        
                        <Form.Control.Feedback type="invalid">
                          {errors.email}
                        </Form.Control.Feedback>
                      </Col>
                    </Form.Row>  
                  </FormGroup>  
                  <FormGroup controlId="phone">
                    <Form.Row>
                      <FormLabel column lg={3}>Telefon</FormLabel>
                      <Col>
                        <FormControl 
                          type="text"
                          name="phone"
                          value={values.phone!}
                          onChange={handleChange}
                          //onChange={async(e) => {await handleChange(e); validateField('phone');}}
                          //onBlur={() =>validateField('phone')}
                          isValid={touched.phone && !errors.phone} 
                          isInvalid={!!errors.phone}
                        />
                        
                        <Form.Control.Feedback type="invalid">
                          {errors.phone}
                        </Form.Control.Feedback>
                      </Col>
                    </Form.Row>  
                  </FormGroup>  

                  <FormGroup controlId="address">
                    <Form.Row>
                      <h5 style={{fontWeight: 'bold'}}>Adresa</h5>
                    </Form.Row>
                  </FormGroup>
                  <Form.Row>
                    <FormGroup as={Col}  controlId="street">
                      <FormLabel>Ulice</FormLabel>
                      <FormControl 
                        type="text"
                        name="street"
                        value={values.street!}
                        onChange={handleChange}
                        //onChange={async(e) => {await handleChange(e); validateField('street');}}
                        //onBlur={() =>validateField('street')}
                        isValid={touched.street && !errors.street} 
                        isInvalid={!!errors.street}
                      />
                      <Form.Control.Feedback type="invalid">
                        {errors.street}
                      </Form.Control.Feedback>
                    </FormGroup>
                    <FormGroup as={Col} lg={2} controlId="houseNumber">
                      <FormLabel>ČP</FormLabel>
                        <FormControl 
                          type="text"
                          name="houseNumber"
                          value={values.houseNumber!}
                          onChange={handleChange}
                          //onChange={async(e) => {await handleChange(e); validateField('houseNumber');}}
                          //onBlur={() =>validateField('houseNumber')}

                          isValid={touched.houseNumber && !errors.houseNumber} 
                          isInvalid={!!errors.houseNumber}
                        />
                        
                        <Form.Control.Feedback type="invalid">
                          {errors.houseNumber}
                        </Form.Control.Feedback>
                      </FormGroup>
                      <FormGroup as={Col} lg={2} controlId="identificationNumber">
                        <FormLabel>ČO</FormLabel>
                        <FormControl 
                          type="text"
                          name="identificationNumber"
                          value={values.identificationNumber!}
                          onChange={handleChange}
                            
                        />
                        <Form.Control.Feedback type="invalid">
                          {errors.identificationNumber}
                        </Form.Control.Feedback>
                    </FormGroup>
                  </Form.Row>

                  <Form.Row>  
                    <FormGroup as={Col} lg={3} controlId="postalCode">
                      <FormLabel>PSČ</FormLabel>
                      <FormControl 
                        type="text"
                        name="postalCode"
                        value={values.postalCode!}
                        onChange={handleChange}
                        //onChange={async(e) => {await handleChange(e); validateField('postalCode');}}
                        //onBlur={() =>validateField('postalCode')}

                        isValid={touched.postalCode && !errors.postalCode} 
                        isInvalid={!!errors.postalCode}
                      />
                        
                      <Form.Control.Feedback type="invalid">
                        {errors.postalCode}
                      </Form.Control.Feedback>
                      </FormGroup>
                      <FormGroup as={Col} controlId="city">
                        <FormLabel>Město</FormLabel>
                        <FormControl 
                          type="text"
                          name="city"
                          value={values.city!}
                          onChange={handleChange}
                          //onChange={async(e) => {await handleChange(e); validateField('city');}}
                          //onBlur={() =>validateField('city')}
                          isValid={touched.city && !errors.city} 
                          isInvalid={!!errors.city}
                        />
                        
                        <Form.Control.Feedback type="invalid">
                          {errors.city}
                        </Form.Control.Feedback>
                      </FormGroup> 
                    </Form.Row>             




                    <FormGroup controlId="address">
                    <Form.Row>
                      <h5 style={{fontWeight: 'bold'}}>Souhlasy</h5>
                    </Form.Row>
                  </FormGroup>

                  <FormGroup controlId="yesOne">
                    <Form.Row>
                      <Col md="1">
                        <FormControl 
                          type="checkbox"
                          name="yesOne"
                          size="sm"
                          className="checkbox-small"
                          //value={values.taxAble!}
                          onChange={handleChange}
                          //onChange={async(e) => {await handleChange(e); validateField('yesOne');}}
                          //onBlur={() =>validateField('yesOne')}
                          checked={values.yesOne}
                          
                          isValid={touched.yesOne && !errors.yesOne} 
                          isInvalid={!!errors.yesOne}
                        />
                        <Form.Control.Feedback type="invalid">
                          {errors.yesOne}
                        </Form.Control.Feedback>
                        </Col>
                        <Col>
                          Odesláním tohoto formuláře potvrzuji, že jsem se seznámil se stanovami spolku, souhlasím s nimi a chci se stát členem.
                          <br />Odkaz na&nbsp;
                          <a href="https://docs.google.com/document/d/1n3f6PcW1VOqsbl4iFkPj-Y9lEQNdXWdLDcS99QJ1i_0/edit?usp=sharing" target="_blank" rel="noopener noreferrer">Stanovy spolku</a>.

                        </Col>
                    </Form.Row>  
                  </FormGroup>

                  <FormGroup controlId="yesTwo">
                    <Form.Row>
                      <Col md="1">
                        <FormControl 
                          type="checkbox"
                          name="yesTwo"
                          size="sm"
                          className="checkbox-small"
                          //value={values.taxAble!}
                          onChange={handleChange}
                          //onChange={async(e) => {await handleChange(e); validateField('yesTwo');}}
                          //onBlur={() =>validateField('yesTwo')}
                          checked={values.yesTwo}
                          
                          isValid={touched.yesTwo && !errors.yesTwo} 
                          isInvalid={!!errors.yesTwo}
                        />
                        <Form.Control.Feedback type="invalid">
                          {errors.yesTwo}
                        </Form.Control.Feedback>
                        </Col>
                        <Col>
                          Souhlasím se správou, zpracováním a uchováváním svých osobních údajů pro vnitřní potřeby zapsaného spolku. Tyto činnosti budou prováděny v souladu s příslušnými právními předpisy, zejména se zákonem 101/2000 Sb. o ochraně osobních údajů, v platném znění a Nařízením Evropského parlamentu a Rady (EU) 2016/679 ze dne 27. dubna 2016 O ochraně fyzických osob v souvislosti se zpracováním osobních údajů a volném pohybu těchto údajů a o zrušení směrnice 95/46/ES (dále jen „Nařízení“). Tento souhlas je poskytnut na dobu mého členství v zapsaném spolku a dále ještě po dobu 2 let. Potvrzuji, že všechny údaje jsou pravdivé a aktuální k dnešnímu dni.
                        </Col>
                    </Form.Row>  
                  </FormGroup>

                  <FormGroup controlId="yesThree">
                    <Form.Row>
                      <Col md="1">
                        <FormControl 
                          type="checkbox"
                          name="yesThree"
                          size="sm"
                          className="checkbox-small"
                          //value={values.taxAble!}
                          onChange={handleChange}
                          //onChange={async(e) => {await handleChange(e); validateField('yesThree');}}
                          //onBlur={() =>validateField('yesThree')}
                          checked={values.yesThree}
                          
                          isValid={touched.yesThree && !errors.yesThree} 
                          isInvalid={!!errors.yesThree}
                        />
                        <Form.Control.Feedback type="invalid">
                          {errors.yesThree}
                        </Form.Control.Feedback>
                        </Col>
                        <Col>
                          Souhlasím, aby v souladu s příslušnými právními předpisy a Nařízením Spolek zpracoval moje: fotografie, videa, zvukové záznamy, sportovní výsledky za účelem marketingu, prezentace na webu, prezentace na sociálních sítích, prezentace ve výroční zprávě a dalších informačních materiálech.
                        </Col>
                    </Form.Row>  
                  </FormGroup>

                  <FormGroup controlId="yesFour">
                    <Form.Row>
                      <Col md="1">
                        <FormControl 
                          type="checkbox"
                          name="yesFour"
                          size="sm"
                          className="checkbox-small"
                          //value={values.taxAble!}
                          onChange={handleChange}
                          //onChange={async(e) => {await handleChange(e); validateField('yesFour');}}
                          //onBlur={() =>validateField('yesFour')}
                          checked={values.yesFour}
                          
                          isValid={touched.yesFour && !errors.yesFour} 
                          isInvalid={!!errors.yesFour}
                        />
                        <Form.Control.Feedback type="invalid">
                          {errors.yesFour}
                        </Form.Control.Feedback>
                        </Col>
                        <Col>
                        Souhlasím, aby Správce zpracoval i mé: telefonní číslo, e-mail, rodné číslo, adresu za účelem vedení evidence členské základny, členských příspěvků a s tím souvisejícími činnostmi. Souhlasím, aby údaje byly zpracovávány a uchovávány po celou dobu trvání účelu zpracování (tedy i po tom, kdy přestanu vykonávat činnost, pro kterou jsem Správcem evidován).
                        </Col>
                    </Form.Row>  
                  </FormGroup>


                  <FormGroup controlId="noAdult">
                    <Form.Row>
                      <h5 style={{fontWeight: 'bold'}}>Nezletilá osoba</h5>
                      V případě, že vyplňujete jménem nezletilé osoby, uveďte prosím Vaše celé jméno a vztah k zapisované osobě.
                    </Form.Row>
                  </FormGroup>
                  
                  <FormGroup controlId="adultPerson">
                    <Form.Row>
                      <FormLabel column lg={3}>Jméno</FormLabel>
                      <Col>
                        <FormControl 
                          type="text"
                          name="adultPerson"
                          value={values.adultPerson!}
                          onChange={handleChange}
                          //onChange={async(e) => {await handleChange(e); validateField('adultPerson');}}
                          //onBlur={() => validateField('adultPerson')}
                          isValid={touched.adultPerson && !errors.adultPerson} 
                          isInvalid={!!errors.adultPerson}
                        />
                        
                        <Form.Control.Feedback type="invalid">
                          {errors.adultPerson}
                        </Form.Control.Feedback>
                      </Col>
                    </Form.Row>  
                  </FormGroup>  
                  {error}
                  <br />
                  <Button type="submit" variant="success" disabled={!(isValid) || loading} block>{loading ? 'Ukládám změny...' : 'Uložit změny'}</Button>
                  
                </Form>
              )}
            </Formik>  
          </div>
        </Modal.Body>

      </Modal>
      
    </>
  )  
  
}

export default withRouter(observer(MembersUpdate))
