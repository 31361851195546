import { types } from 'mobx-state-tree'

const AgeCategories = types.model('AgeCategories',{
  id: types.optional(types.string, ''),
  idLevels: types.maybeNull(types.string),
  idAgeCategories: types.maybeNull(types.string),
  needPoints: types.maybeNull(types.string),
  needFinals: types.maybeNull(types.string),
  active: types.maybeNull(types.string),
  ageCategoryName: types.maybeNull(types.string)
})


export const DialsLevels = types
  .model({
    idLevels: types.optional(types.string, ''),
    idStyles: types.optional(types.string, ''),
    idClasses: types.optional(types.string, ''),
    styleShortcut: types.maybeNull(types.string),
    styleName: types.maybeNull(types.string),
    styleType: types.maybeNull(types.string),
    classShortcut: types.maybeNull(types.string),
    className: types.maybeNull(types.string),
    needPoints: types.maybeNull(types.string),
    needFinals: types.maybeNull(types.string),
    levelOrder: types.maybeNull(types.string),
    defaultForNew: types.maybeNull(types.string),
    defaultForNewRegistered: types.maybeNull(types.string),
    active: types.maybeNull(types.string),
    ageCategories: types.array(AgeCategories)
  })
  .views(self => ({
    get isDefault() {
      
      return self.defaultForNew === '1' ? true:false
    },
    get isDefaultRegistered() {
      
      return self.defaultForNewRegistered === '1' ? true:false
    },
  }))
  .actions(self => ({
    

  }))
