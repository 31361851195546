import { types } from 'mobx-state-tree'


export const CompetitionsListSchedule = types
  .model({
    idCompetitions: types.optional(types.string, ''),
    date: types.maybeNull(types.string),
    name: types.maybeNull(types.string),
    logo: types.maybeNull(types.string),
    applicationsDate: types.maybeNull(types.string),
    allowSolos: types.maybeNull(types.string),
    allowDuets: types.maybeNull(types.string),
    allowTeams: types.maybeNull(types.string),
    allowProams: types.maybeNull(types.string),

    allowCouples: types.maybeNull(types.string),
    allowPara: types.maybeNull(types.string)
  })
  .views(self => ({
    get applicationsAfterTerm() {
      console.log(new Date(self.applicationsDate!).setHours(0, 0, 0, 0))
      console.log(new Date().setHours(0, 0, 0, 0))
      if (new Date(self.applicationsDate!).setHours(0, 0, 0, 0) > new Date().setHours(0, 0, 0, 0)) {console.log(false)} else {console.log(true)}

      return new Date(self.applicationsDate!).setHours(0, 0, 0, 0) >= new Date().setHours(0, 0, 0, 0) ? false:true
    },
  }))
  .actions(self => ({
    

  }))
