import { types } from 'mobx-state-tree'

export const DialsAgeCategories = types
  .model({
    idAgeCategories: types.optional(types.string, ''),
    shortcut: types.maybeNull(types.string),
    ageCategoryName: types.maybeNull(types.string),
    ageCategoryNameEN: types.maybeNull(types.string),
    ageFrom: types.maybeNull(types.string),
    ageTo: types.maybeNull(types.string),
    applicationsAgeFrom: types.maybeNull(types.string),
    applicationsAgeTo: types.maybeNull(types.string),
    checkAgeForAll: types.maybeNull(types.string),
    main: types.maybeNull(types.string),
    active: types.maybeNull(types.string),
  })
  .views(self => ({
    get isCheckAgeForAll() {
      
      return self.checkAgeForAll === '1' ? true:false
    },
    get isMain() {
      
      return self.main === '1' ? true:false
    },
  }))
  .actions(self => ({
    

  }))
