import { types } from 'mobx-state-tree'

export const DialsStyles = types
  .model({
    idStyles: types.optional(types.string, ''),
    shortcut: types.maybeNull(types.string),
    styleName: types.maybeNull(types.string),
    styleType: types.maybeNull(types.string),
    active: types.maybeNull(types.string),
  })
  .views(self => ({
    
  }))
  .actions(self => ({
    

  }))
