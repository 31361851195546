import React, { useState }  from 'react'
import { withRouter, RouteComponentProps } from 'react-router-dom'
import { getToken } from '../../../Utils/Common'

import ErrorAlert from '../../../parts/errorAlert/ErrorAlert.part'

import { Button,  Form, Col, Row, Alert, Modal, Table, Badge, Nav, Tabs, Tab } from 'react-bootstrap'

import FormControl from 'react-bootstrap/FormControl'
import FormGroup from 'react-bootstrap/FormGroup'
import FormLabel from 'react-bootstrap/FormLabel'

import * as Constants from '../../../Constants/Constants'
import axios from 'axios';

import { confirm } from "../../../Utils/Confirmation"

import { useStore } from '../../../store'
import { observer } from 'mobx-react'

import { Formik } from 'formik'
import * as Yup from 'yup'

import moment from 'moment'

import MemberLevelsUpdate from './Members.Level.Update.part'
import NumberFormat from 'react-number-format'

interface MembersDetailProps {
  id: string
}

const MembersDetail: React.FC<MembersDetailProps & RouteComponentProps>  = (props:any) => {
  // Nastavení práv pro editaci


  // Nastavení stavů pro zobrazení
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState(null)
  const [showInfo, setShowInfo] = useState(false)
  const [showForm, setShowForm] = useState('')
  const [show, setShow] = useState(false)
  const [showE, setShowE] = useState(false)

  const [activeNav, setActiveNav] = useState('classes')

  // Připojení store
  const rootStore = useStore()
  const member = rootStore.getDancerCALById(props.id)
  const styles = rootStore.dialsStyles
  
  // Otevírání a zavírání modálního okna Změny hesla
  const handleClose = () => {
    setShow(false)
    setShowE(false)
    setError(null)
    sessionStorage.removeItem('autoCloseModal')
  }
  const handleShow = () => {
    sessionStorage.removeItem('autoCloseModal')
    setError(null)
    setShowInfo(false)
    setShowForm('')
    setShow(true)
  }

  



  // Schéma validace formuláře
  const schemaUpdate = Yup.object().shape({
    surName: Yup.string()
      .required('Příjmení musí být vyplněno!'),
    /*
    value: Yup.string()
    .required('Hodnota musí být vyplněna!'),*/
  })   
  return (
    <>
      
        <>
          <Button variant="secondary" size="sm" onClick={handleShow}>
            <i className="fas fa-info-circle"></i>
          </Button>
          <span style={{marginLeft: '10px'}}></span>
          
        </>
      
      


      <Modal 
        show={show} 
        onHide={handleClose} 
        dialogClassName="modal-90w"
      backdrop="static"
      aria-labelledby="example-custom-modal-styling-title"
        >
        <Modal.Header closeButton>
          <Modal.Title>Detail sportovce {member?.id}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row>
            <Col md={6}>
              <Row>
                <Col md={4} style={{fontWeight: 'bold'}}>Příjmení:</Col>
                <Col>{member?.surName}</Col>
              </Row>
              <Row>
                <Col md={4} style={{fontWeight: 'bold'}}>Jméno:</Col>
                <Col>{member?.firstName}</Col>
              </Row>
              <Row>
                <Col md={4} style={{fontWeight: 'bold'}}>Datum narození:</Col>
                <Col>{moment(member?.birthdate).format("DD.MM.YYYY")}</Col>
              </Row>
              <Row>
                <Col md={4} style={{fontWeight: 'bold'}}>Rodné číslo:</Col>
                <Col>{member?.personalIdentificationNumber}</Col>
              </Row>
              <Row>
                <Col md={4} style={{fontWeight: 'bold'}}>Taneční klub:</Col>
                <Col>{member?.club}</Col>
              </Row>
              <Row>
                <Col md={4} style={{fontWeight: 'bold'}}>Země:</Col>
                <Col>{member?.country}</Col>
              </Row>
            
            </Col>
            <Col md={6}>
              <Row>
                <Col md={4} style={{fontWeight: 'bold'}}>Email:</Col>
                <Col>{member?.email}</Col>
              </Row>
              <Row>
                <Col md={4} style={{fontWeight: 'bold'}}>Telefon:</Col>
                <Col>{member?.phone}</Col>
              </Row>
              <Row>
                <Col md={4} style={{fontWeight: 'bold'}}>Adresa:</Col>
                <Col>{member?.street}&nbsp;{member?.houseNumber}{member?.identificationNumber !== '' && ' / '}{member?.identificationNumber}<br />{member?.postalCode}&nbsp;{member?.city}</Col>
              </Row>

              <Row>
                <Col md={4} style={{fontWeight: 'bold'}}>Zdr. prohlídka do:</Col>
                <Col>{moment(member?.medicalExaminationTo).format("DD.MM.YYYY")}</Col>
              </Row>
              <Row>
                <Col md={4} style={{fontWeight: 'bold'}}>Členské př.:</Col>
                <Col>{member?.lastPaidYear! < new Date().getFullYear() ? <Badge style={{marginLeft: '5px'}} variant="danger">Nezaplaceno</Badge> : <Badge style={{marginLeft: '5px'}} variant="success">Zaplaceno</Badge>}</Col>
              </Row>
            </Col>
          </Row>
          
          
          <br />

          <Tabs
            id="controlled-tab-example"
            activeKey={activeNav}
            onSelect={(k) => setActiveNav(k!)}
            className='invoice-items'
          >
            <Tab eventKey="classes" tabClassName={'tab-with-data'} title="Výkonnostní třídy">
            </Tab>
            <Tab eventKey="payments" tabClassName={member?.payments!.length! > 0 ? 'tab-with-data':''} title="Platby">
            </Tab>
          </Tabs>

          <div style={{border: '1px solid #C0D1E1', padding: '5px', borderTopWidth: '0px'}}>
            
            {activeNav === 'classes' && <>
              <Row>
                <Col md={4}><strong>Standardní tance SÓLO:</strong></Col><Col>{member?.classSoloSTTName}</Col>
              </Row>
              <Row>
                <Col md={4}><strong>Latinskoamerické tance SÓLO:</strong></Col><Col>{member?.classSoloLATName}</Col>
              </Row>
              <Row>
                <Col md={4}><strong>Karibské tance SÓLO:</strong></Col><Col>{member?.classSoloCARName}</Col>
              </Row>
            </>}

            {activeNav === 'payments' && <>
              <Table hover size='sm' style={{marginTop: '10px'}}>
              <thead>
                <tr className="jsw-table-head">
                  <th className="jsw-table-head" title='Datum platby'>Datum platby</th>
                  <th className="jsw-table-head" title='Variabilní symbol'>Variabilní symbol</th>
                  <th className="jsw-table-head row-slim" title='Rok'>Rok</th>
                  <th className="jsw-table-head" title='Částka' style={{textAlign: 'right'}}>Částka</th>
                  
                  <th className="jsw-table-head" title='ID transakce'>ID transakce</th>
                  <th className="jsw-table-head" title='Poznámka'>Poznámka</th>
                </tr>
              </thead>
              <tbody>
                {member?.payments.map(payment => ( 
                  <tr key={payment.id}>
                    <td>{moment(payment.createDate).format('DD.MM.YYYY')}</td>
                    <td>{payment.variableSymbol}</td>
                    <td>{payment.year}</td>
                    <td style={{textAlign: 'right'}}><NumberFormat displayType={'text'} thousandSeparator={'.'} decimalSeparator={','} decimalScale={2} fixedDecimalScale={true} value={payment.price} /></td>
                    
                    <td>{payment.idTransaction}</td>
                    <td>{payment.note}</td>
                  </tr>
                ))}
              </tbody>
            </Table>
            </>}

          </div>






        
          {styles.map(styl => (
          <Row style={{display: 'none'}} key={styl.idStyles}>
            <Col md={6} style={{fontWeight: 'bold'}}>{styl.styleName}</Col>
            
          </Row>


          ))}



          <Table>

          </Table>



          <div className={showForm} >
            
                
          </div>
        </Modal.Body>

      </Modal>
      
    </>
  )  
  
}

export default withRouter(observer(MembersDetail))
