import React, { useState }  from 'react'
import { withRouter, RouteComponentProps } from 'react-router-dom'
import { getToken } from '../../../Utils/Common'

import ErrorAlert from '../../../parts/errorAlert/ErrorAlert.part'

import { Button,  Form, Col, Alert, Modal, Dropdown, DropdownButton, ButtonGroup } from 'react-bootstrap'

import FormControl from 'react-bootstrap/FormControl'
import FormGroup from 'react-bootstrap/FormGroup'
import FormLabel from 'react-bootstrap/FormLabel'

import * as Constants from '../../../Constants/Constants'
import axios from 'axios';

import { confirm } from "../../../Utils/Confirmation"

import { useStore } from '../../../store'
import { observer } from 'mobx-react'

import { Formik } from 'formik'
import * as Yup from 'yup'

import moment from 'moment'

interface MembersMedicalExaminationToUpdateProps {
  id: string
}

const MembersMedicalExaminationToUpdate: React.FC<MembersMedicalExaminationToUpdateProps & RouteComponentProps>  = (props:any) => {
  // Nastavení práv pro editaci


  // Nastavení stavů pro zobrazení
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState(null)
  const [showInfo, setShowInfo] = useState(false)
  const [showForm, setShowForm] = useState('')
  const [show, setShow] = useState(false)
  const [showE, setShowE] = useState(false)

  // Připojení store
  const rootStore = useStore()
  const member = rootStore.getDancerCALById(props.id)
  
  // Otevírání a zavírání modálního okna Změny hesla
  const handleClose = () => {
    setShow(false)
    setShowE(false)
    setError(null)
    sessionStorage.removeItem('autoCloseModal')
  }
  const handleShow = () => {
    sessionStorage.removeItem('autoCloseModal')
    setError(null)
    setShowInfo(false)
    setShowForm('')
    setShow(true)
  }

  

  // Funkce pro změnu kontaktu
  const handleUpdate = (medicalExaminationToData:string) => {
    setError(null)
    setLoading(true);
    
    // Zpracování požadavku
    (async () => {

      // Kontrola přihlášení  
      //const aToken = await getToken()
      if (localStorage.getItem('isLogged') !== 'is') { 
        sessionStorage.setItem('automaticLogout', '1')
        props.history.push('/logout')
      }
      
      // Zavolání API
      await axios.post(Constants.URL_DANCER_CAL_MEDICAL_UPDATE, { idMembers: props.id, userId: rootStore.loggedUsers[0]?.idUsers, userPassword: localStorage.getItem('PIN')!, medicalExaminationTo: medicalExaminationToData}
        ).then(response => {
          
          // Změna dat ve Store
          
          rootStore.fetchDancerListCAL(rootStore.loggedUsers[0]?.idUsers, localStorage.getItem('PIN')!)

          //rootStore.fetchCustomerOpened()  
          // Nastavovačky, co se má v případě, že je to OK, zobrazit
          setLoading(false)
          setShowForm('nodisplay')
          setShowInfo(true)
          sessionStorage.setItem('autoCloseModal','1');

          // Po 2800 ms celé modální okno zavřeme
          setTimeout(() => {
            if (sessionStorage.getItem('autoCloseModal') === '1') {  
              setShow(false)
              setError(null)
              sessionStorage.removeItem('autoCloseModal')
              //setShowInfo(false)
              //setShowForm('')
            }
          }, 2800);

        }).catch(error => {
          setLoading(false)
          const errorMessage:any = (<ErrorAlert errorData={error} />)
          setError(errorMessage)
        });
    })()
  }

  // Schéma validace formuláře
  const schemaUpdate = Yup.object().shape({
    /*surName: Yup.string()
      .required('Příjmení musí být vyplněno!'),*/
    /*
    value: Yup.string()
    .required('Hodnota musí být vyplněna!'),*/
  })   
  return (
    <>
      
        <>
          <Button variant="danger" size="sm" onClick={handleShow}>
            <i className="fas fa-briefcase-medical"></i>
          </Button>
          <span style={{marginLeft: '10px'}}></span>
          
        </>
      
      
      

      <Modal 
        show={show} 
        onHide={handleClose} 
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered>
        <Modal.Header closeButton>
          <Modal.Title>Úprava sportovce {member?.id} - {member?.surName} {member?.firstName}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Alert variant="success" show={showInfo}>
            <strong>Sportovec byl úspěšně upraven!​</strong>
            
          </Alert>  
          <div className={showForm} >
            <Formik
              validationSchema={schemaUpdate}
              initialValues={{ 
                medicalExaminationTo: moment(member?.medicalExaminationTo).format("YYYY-MM-DD") 
              }}
              onSubmit={(values, { setSubmitting }) => {
                setTimeout(() => {
                  handleUpdate(values.medicalExaminationTo!)              
                  setSubmitting(false);
                }, 400);
              }}
            >
              {({ 
                handleSubmit,
                handleChange,
               
                values,
                touched,
                isValid,
                errors,
                isSubmitting }) => (
                <Form onSubmit={handleSubmit}>
                  
                  <FormGroup controlId="medicalExaminationTo">
                    <Form.Row>
                      <FormLabel column lg={3}>Zdravotní prohlídka do</FormLabel>
                      <Col>
                        <FormControl 
                          type="date"
                          name="medicalExaminationTo"
                          value={values.medicalExaminationTo!}
                          onChange={handleChange}
                          isValid={touched.medicalExaminationTo && !errors.medicalExaminationTo} 
                          isInvalid={!!errors.medicalExaminationTo}
                        />
                        
                        <Form.Control.Feedback type="invalid">
                          {errors.medicalExaminationTo}
                        </Form.Control.Feedback>
                      </Col>
                    </Form.Row>  
                  </FormGroup>
                   
                   
                  {error}
                  <br />
                  <Button type="submit" variant="success" disabled={!(isValid) || loading} block>{loading ? 'Ukládám změny...' : 'Uložit změny'}</Button>
                  
                </Form>
              )}
            </Formik>  
          </div>
        </Modal.Body>

      </Modal>
      
    </>
  )  
  
}

export default withRouter(observer(MembersMedicalExaminationToUpdate))
