import { types } from 'mobx-state-tree'

export const CustomerUsers = types
  .model({
    id: types.optional(types.string, ''),
    email: types.optional(types.string,''),
    surName: types.optional(types.string, ''),
    firstName: types.optional(types.string, ''),
    phone: types.optional(types.string, ''),
    isEmailConfirmed: types.optional(types.boolean, false),
    isAdmin: types.optional(types.boolean, false),
    registeredAt: types.maybeNull(types.string)  
  })
  .views(self => ({
    get fullName() {
      return `${self.firstName} ${self.surName}`
    }
  }))
  .actions(self => ({
    setFirstName(newName: string | undefined) {
      if (newName) {
        self.firstName = newName
      }
    },
    setSurName(newName: string | undefined) {
      if (newName) {
        self.surName = newName
      }
    },
    setEmail(newEmail: string | undefined) {
      if (newEmail) {
        //self.email = email
      }
    },
    setPhone(newPhone: string | undefined) {
      if (newPhone) {
        self.phone = newPhone
      }
    }
  }))
