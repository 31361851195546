import { types } from 'mobx-state-tree'
//import { useUpgradeStore } from '../store'

//const rootUpgradeStore = useUpgradeStore()

export const CustomerOpenedDocuments = types
  .model({
    id: types.optional(types.string, ''),
    documentName: types.optional(types.string, ''),
    documentType: types.optional(types.string, ''),
    customerId: types.optional(types.string, ''),
    programId: types.maybeNull(types.string),
    upgradeScenarioId: types.maybeNull(types.string),
    proformaConfirmationNumber: types.maybeNull(types.string),
    paymentConfirmationNumber: types.maybeNull(types.string),
    totalPrice: types.maybeNull(types.number),
    paymentDate: types.maybeNull(types.string),
  })
  .views(self => ({
    /*get program() {
      return rootUpgradeStore.getUpgradeOffersScenarioById(self.upgradeScenarioId!)?.program.name
    },*/
  }))
  .actions(self => ({
    

  }))
