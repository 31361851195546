export const errorNames = [
  {
    'code': 1000,
    'description': 'Obecná chyba.'
  },{
    'code': 1001,
    'description': 'Nebyl zaslán token.'
  },{
    'code': 1002,
    'description': 'Nebyl zaslán RefreshToken.'
  },{
    'code': 1003,
    'description': 'Odkaz pro obnovu hesla již není platný. V případě potřeby zažádejte znovu o obnovu hesla.'
  },{
    'code': 1004,
    'description': 'Neplatný uživatel.'
  },{
    'code': 1005,
    'description': 'ID je vyžadováno.'
  },{
    'code': 1006,
    'description': 'Zaslán neplatný parametr pro stránkování.'
  },{
    'code': 1007,
    'description': 'Zaslán neplatný parametr pro filtrování.'
  },{
    'code': 1010,
    'description': 'Není vyplněn e-mail.'
  },{
    'code': 1011,
    'description': 'Není vyplněno heslo.'
  }, {
    'code': 1012,
    'description': 'Potvrzení hesla není správně.'
  }, {
    'code': 1013,
    'description': 'Heslo může změnit pouze přihlášený uživatel.'
  },{
    'code': 1014,
    'description': 'Není vyplněno křestní jméno.'
  },{
    'code': 1015,
    'description': 'Není vyplněno příjmení.'
  },{
    'code': 1016,
    'description': 'Není vyplněno původní heslo.'
  },{
    'code': 1020,
    'description': 'Firma neexistuje.'
  },{
    'code': 1021,
    'description': 'Nepovolený typ kontaktu.'
  },{
    'code': 1022,
    'description': 'E-mail byl zadán ve špatném formátu.'
  },{
    'code': 1023,
    'description': 'Telefonní číslo obsahuje nepovolené znaky.'
  },{
    'code': 1024,
    'description': 'Nepovolený typ adresy.'
  },{
    'code': 1025,
    'description': 'Není vyplněn název firmy.'
  },{
    'code': 1026,
    'description': 'Není zadán typ kontaktu.'
  },{
    'code': 1027,
    'description': 'Není vyplněn kontakt.'
  },{
    'code': 1028,
    'description': 'Není zadán typ kontaktu.'
  },{
    'code': 1029,
    'description': 'Není vyplněna ulice.'
  },{
    'code': 1030,
    'description': 'Není vyplněno číslo popisné.'
  },{
    'code': 1031,
    'description': 'Není vyplněno PSČ.'
  },{
    'code': 1032,
    'description': 'Není vyplněno město.'
  },{
    'code': 1033,
    'description': 'Není vyplněno licenční číslo.'
  },{
    'code': 1034,
    'description': 'Není vyplněno IČ.'
  },{
    'code': 1035,
    'description': 'Není vyplněna fakturační adresa.'
  },

  {
    'code': 2000,
    'description': 'Obecná chyba.'
  },{
    'code': 2001,
    'description': 'Neznámá chyba.'
  },{
    'code': 2002,
    'description': 'Odkaz již není platný.'
  },{
    'code': 2003,
    'description': 'Hledání podle zadaného údaje není podporováno.'
  },{
    'code': 2004,
    'description': 'Nelze smazat posledního správce otevřené firmy.'
  },{
    'code': 2100,
    'description': 'Účet není aktivován. Aktivaci provedete kliknutím na odkaz v e-mailu s potvrzením registrace.'
  },{
    'code': 2101,
    'description': 'Špatné uživatelské jméno nebo heslo.'
  },{
    'code': 2102,
    'description': 'Potvrzovací klíč neexistuje.'
  },{
    'code': 2103,
    'description': 'Registrace není možná pro již existujícího uživatele.'
  },{
    'code': 2104,
    'description': 'Platnost pozvánky vypršela.'
  },{
    'code': 2105,
    'description': 'Obnovovací klíč uživatele neexistuje. Přihlašte se znovu.'
  },{
    'code': 2106,
    'description': 'Platnost obnovovacího klíče vypršela. Přihlašte se znovu.'
  },{
    'code': 2107,
    'description': 'Použitou pozvánku nelze smazat.'
  },{
    'code': 2108,
    'description': 'Staré heslo není správné.'
  },{
    'code': 2109,
    'description': 'Odkaz pro obnovu hesla již není platný. V případě potřeby zažádejte znovu o obnovu hesla.'
  },{
    'code': 2300,
    'description': 'Uživatel nemá přístupová práva ke správě firmy.'
  },{
    'code': 2301,
    'description': 'Uživatel nemůže odebrat sám sebe.'
  },{
    'code': 2302,
    'description': 'Kontakt není unikátní.'
  },{
    'code': 2303,
    'description': 'Poslední e-mail pro zasílání účetních dokladů nelze odebrat.'
  },{
    'code': 2304,
    'description': 'Posledního uživatele  na úrovni správce nelze odebrat.'
  },
  {
    'code': 23005,
    'description': 'Vytvoření pozvánky pro neaktivního zákazníka není dovoleno.'
  },
  {
    'code': 23006,
    'description': 'Otevření neaktivního zákazníka není dovoleno.'
  },
  {
    'code': 6200,
    'description': 'Převod licence nemůže být proveden.'
  },
  {
    'code': 6201,
    'description': 'Změna stavu licence je povolena pouze pro aktivní licence.'
  },
  {
    'code': 6202,
    'description': 'Cílový zákazník má jinou aktivní licenci.'
  },
  {
    'code': 6203,
    'description': 'Zákazník nemůže mít více aktivních licencí.'
  },
  {
    'code': 6204,
    'description': 'Aktivace licence je dovolena pouze pro poslední verze programů.'
  },
  {
    'code': 6300,
    'description': 'Změna příznaku aktivní není u systémové šablony povolena.'
  },
  {
    'code': 6301,
    'description': 'Smazání systémové šablony není povoleno.'
  },
  {
    'code': 6302,
    'description': 'Smazání použité šablony není povoleno.'
  },

  {
    'code': 30002,
    'description': 'Modul Analýzy není možné zakoupit bez modulu Účetnictví (alespoň 1 PC).'
  },
  {
    'code': 30003,
    'description': 'U licence typu A nebo B je možné zadat pouze 1 PC.'
  },
  {
    'code': 30004,
    'description': 'U licence typu C nebo D je možné zadat minimálně 2 PC.'
  },
  {
    'code': 30006,
    'description': 'Modul Analýzy není možné zakoupit bez modulu Účetnictví (alespoň 1 PC).'
  },
  {
    'code': 30007,
    'description': 'U licence typu B je možné zadat pouze 1 PC.'
  },
  {
    'code': 30008,
    'description': 'U licence typu D je možné zadat minimálně 2 PC.'
  },
  {
    'code': 30009,
    'description': 'Modul Maloobchod není možné zakoupit bez modulu Sklad (alespoň 1 PC).'
  },
  {
    'code': 30010,
    'description': 'Modul E-shopy není možné zakoupit bez modulu Sklad (alespoň 1 PC).'
  },
  {
    'code': 30050,
    'description': 'Objednávka pro daného zákazníka a scénář již existuje.'
  },
  {
    'code': 300501,
    'description': 'Zákazník nemá licenci a daný scénář.'
  },
  {
    'code': 300502,
    'description': 'Zákazník má licenci a daný scénář.'
  },
  {
    'code': 300503,
    'description': 'Cena položky je mimo povolený rozsah.'
  },
  {
    'code': 300504,
    'description': 'Povolený stav je pouze Nabídnuto.'
  },
  {
    'code': 300505,
    'description': 'Změna stavu Vytvořeno není povolena.'
  },
  {
    'code': 300508,
    'description': 'Zákazník nemá aktuální licenci programu.'
  },
  {
    'code': 300509,
    'description': 'Obnovení objednávky do původního stavu není pro zákazníka dovoleno.'
  },
  {
    'code': 300510,
    'description': 'Obnovení objednávky do původního stavu není povoleno pro původní objednávku.'
  },
  {
    'code': 300511,
    'description': 'Obnovení objednávky do původní podoby není pro daný stav povoleno.'
  },
  {
    'code': 50000,
    'description': 'Dokument, který se pokoušéte otevřít  není v tuto chvíli dostupný. Zkuste to prosím později nebo kontektujte naše obchodní oddělení.'
  },
]