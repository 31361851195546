//import {createAuthProvider} from './Utils/jsw-token-auth'
import {createAuthProvider} from 'react-token-auth'
import * as Constants from './Constants/Constants';

export const [useAuth, authFetch, login, logout] =
    createAuthProvider<{ accessToken: string, refreshToken: string }>({
        accessTokenKey: 'accessToken',
        onUpdateToken: (token) => fetch(Constants.URL_REFRESH_TOKEN, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({ refreshToken: token.refreshToken })
        })
        .then(r => r.json())

    });