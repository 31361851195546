import { types } from 'mobx-state-tree'

export const LicensesTransferOldCustomer = types
  .model({
    id: types.optional(types.string, ''),
    accountNumber: types.maybeNull(types.string),
    businessName: types.maybeNull(types.string),
    companyRegistrationNumber: types.maybeNull(types.string)
    
    //count: types.optional(types.number, 0),
    //items: types.maybe(Items)
    //items: types.array(Items)
  })
  .views(self => ({
    
  }))
  .actions(self => ({
    

  }))
