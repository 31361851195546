import React from 'react'
import { NavLink, withRouter } from 'react-router-dom'

import { ROUTES } from '../../Routes'
import { Nav, Button, Form } from 'react-bootstrap'

import { useStore } from '../../store'
import { observer } from 'mobx-react'

function SideMenu(props:any) {

    // Nastavení práv zda jsme Ježci


    const handleMenuClick = () => {
        if (document.body.classList.contains('sb-sidenav-toggled')) {
            document.body.classList.remove('sb-sidenav-toggled')
        }
      }


    
    const rootStore = useStore()
    

    

    if (localStorage.getItem('isLogged')) {
        return( 
            <div id="layoutSidenav_nav">
                <nav className="sb-sidenav accordion sb-sidenav-dark" id="sidenavAccordion">
                    <div className="sb-sidenav-menu">                        
                        <Nav>

                            <div >
                            <div className="sb-sidenav-menu-heading">Evidence</div>
                            </div>
                            
                                <NavLink className="nav-link" activeClassName="active" to={ROUTES.dashboard.getLink()} onClick={handleMenuClick}>
                                    <div className="sb-nav-link-icon"><i className="fas fa-tachometer-alt fa-fw"></i></div> Dashboard
                                </NavLink>
                               
                                {(localStorage.getItem('permissions') === '1' || localStorage.getItem('permissions') === '2') &&
                                <NavLink className="nav-link" activeClassName="active" to={ROUTES.members.getLink()} onClick={handleMenuClick}>
                                    <div className="sb-nav-link-icon"><i className="fa fa-address-card fa-fw"></i></div> Databáze CPADU
                                </NavLink>}

                                {(localStorage.getItem('permissions') === '1' || localStorage.getItem('permissions') === '2') &&
                                <NavLink className="nav-link" activeClassName="active" to={ROUTES.bankAccountTransfersCPADU.getLink()} onClick={handleMenuClick}>
                                    <div className="sb-nav-link-icon"><i className="fa fa-piggy-bank fa-fw"></i></div> Nespárované platby
                                </NavLink>}

                                {(localStorage.getItem('permissions') === '1' || localStorage.getItem('permissions') === '3') &&
                                <NavLink className="nav-link" activeClassName="active" to={ROUTES.membersCAL.getLink()} onClick={handleMenuClick}>
                                    <div className="sb-nav-link-icon"><i className="fa fa-address-card fa-fw"></i></div> Databáze CAL
                                </NavLink>}
                                {(localStorage.getItem('permissions') === '1' || localStorage.getItem('permissions') === '3') &&
                                <NavLink className="nav-link" activeClassName="active" to={ROUTES.bankAccountTransfersCAL.getLink()} onClick={handleMenuClick}>
                                    <div className="sb-nav-link-icon"><i className="fa fa-piggy-bank fa-fw"></i></div> Nespárované platby
                                </NavLink>}
                                

                                
                                {localStorage.getItem('permissions') === '1' && <>
                            <div className="sb-sidenav-menu-heading">Číselníky</div>

                            <NavLink className="nav-link" activeClassName="active" to={ROUTES.dialsStyles.getLink()} onClick={handleMenuClick}>
                                <div className="sb-nav-link-icon"><i className="fas fa-project-diagram fa-fw"></i></div> Taneční styly
                            </NavLink>
                            <NavLink className="nav-link" activeClassName="active" to={ROUTES.dialsCompetitionTypes.getLink()} onClick={handleMenuClick}>
                                <div className="sb-nav-link-icon"><i className="fas fa-project-diagram fa-fw"></i></div> Druhy soutěží
                            </NavLink>
                            <NavLink className="nav-link" activeClassName="active" to={ROUTES.dialsCompetitionVariants.getLink()} onClick={handleMenuClick}>
                                <div className="sb-nav-link-icon"><i className="fas fa-project-diagram fa-fw"></i></div> Varianty soutěží
                            </NavLink>
                            <NavLink className="nav-link" activeClassName="active" to={ROUTES.dialsClasses.getLink()} onClick={handleMenuClick}>
                                <div className="sb-nav-link-icon"><i className="fas fa-project-diagram fa-fw"></i></div> Třídy
                            </NavLink>
                            <NavLink className="nav-link" activeClassName="active" to={ROUTES.dialsAgeCategories.getLink()} onClick={handleMenuClick}>
                                <div className="sb-nav-link-icon"><i className="fas fa-project-diagram fa-fw"></i></div> Věkové kategorie
                            </NavLink>
                            <NavLink className="nav-link" activeClassName="active" to={ROUTES.dialsLevels.getLink()} onClick={handleMenuClick}>
                                <div className="sb-nav-link-icon"><i className="fas fa-project-diagram fa-fw"></i></div> Výkonnostní třídy
                            </NavLink>
                            <NavLink className="nav-link" activeClassName="active" to={ROUTES.dialsCompetitions.getLink()} onClick={handleMenuClick}>
                                <div className="sb-nav-link-icon"><i className="fas fa-project-diagram fa-fw"></i></div> Soutěže
                            </NavLink>
                            </>}
                            

                            
                            <div className="sb-sidenav-menu-heading showMobileNav">Přihlášený uživatel</div>
                            <NavLink className="nav-link showMobileNav" to={ROUTES.myAccount.getLink()} onClick={handleMenuClick}>
                                <div className="sb-nav-link-icon"><i className="fa fa-fingerprint fa-fw"></i></div> Můj účet
                            </NavLink>
                            




                            <NavLink className="nav-link showMobileNav" to={ROUTES.logout.getLink()} onClick={handleMenuClick}><Button variant="danger" size="sm">Odhlásit</Button></NavLink>
                        </Nav>
                    </div>
                    <div className="sb-sidenav-footer">
                        Podpora<br />
                        <i className="fas fa-envelope"></i>&nbsp;&nbsp;<a style={{color: 'rgba(255, 255, 255, 0.5)'}} href="mailto:info@ts-sway.cz">info@ts-sway.cz</a>
                        <br />
                        <i className="fas fa-phone-square-alt"></i>&nbsp;&nbsp;+420 724 284 819
                        <br /><br />
                        <div className="small">© 2025, Jakub Schneider</div>
                    </div>
                </nav>
            </div>
        )
    }
    else {
        return(
            <div id="layoutSidenav_nav"></div>
        )  
    }
      
}
  
export default observer(withRouter(SideMenu))