import { types } from 'mobx-state-tree'


export const OverviewsOffers = types
  .model({
    category: types.optional(types.string, ''),
    type: types.optional(types.string, ''),
    filterId: types.optional(types.number, 0),
    count: types.optional(types.number, 0),
    
  })
  .views(self => ({

  }))
  .actions(self => ({
    

  }))
