import { getToken, errorEvaluation } from '../Utils/Common';
//import React, { useContext } from 'react'
//import { connectReduxDevtools } from 'mst-middlewares'

import { DialsCategories } from './dialsCategories.store'
import { DialsPrograms } from './dialsPrograms.store'
import { DialsProgramVersions } from './dialsProgramVersions.store'
import { DialsLicenseTypes } from './dialsLicenseTypes.store'
import { DialsServices } from './dialsServices.store'
import { DialsModules } from './dialsModules.store'
import { DialsPriceListsModules } from './dialsPriceListsModules.store'
import { DialsPriceListsServices } from './dialsPriceListsServices.store'

import { types, Instance, flow, applySnapshot } from 'mobx-state-tree'
import { sleep } from '../helpers/sleep'

//import { logout } from '../authProvider'

import * as Constants from '../Constants/Constants'
import axios from 'axios'; 

/*
function isNetworkError(err:any) {
  return !!err.isAxiosError && !err.response;
}
*/

export const RootDialsStore = types
  .model({
    dialsCategories: types.array(DialsCategories),
    dialsPrograms: types.array(DialsPrograms),
    dialsProgramVersions: types.array(DialsProgramVersions),
    dialsLicenseTypes: types.array(DialsLicenseTypes),
    dialsServices: types.array(DialsServices),
    dialsModules: types.array(DialsModules),
    dialsPriceListsModules: types.array(DialsPriceListsModules),
    dialsPriceListsServices: types.array(DialsPriceListsServices),
    
  })
  .views(self => ({
    
  }))
  .actions(self => ({

    fetchDialsCategories: flow(function* fetchProjects() {
      try {

       yield sleep(300)
      
       const accessToken = yield getToken()

        const cl = yield axios.get(Constants.URL_DIALS_CATEGORIES,{
          headers: {
            'Authorization': 'Bearer ' + accessToken
          }}).then(response => {
          
          return response.data
          
        }).catch(error => {
          errorEvaluation(error)
        });
        //console.log(cl)
        applySnapshot(self.dialsCategories,cl) 
          
    } catch (error) {
      console.error('Nepodařilo se načíst kategorie!', error)
    } 
    }),

    deleteDialsCategories: flow(function* fetchProjects() {
      try {
        yield sleep(100)
        self.dialsCategories.clear()
                
      } catch (error) {
        console.error('Failed to clear dialsCategories', error)
      }
    }),    

    fetchDialsPrograms: flow(function* fetchProjects() {
      try {

       yield sleep(300)
      
       const accessToken = yield getToken()

        const cl = yield axios.get(Constants.URL_DIALS_PROGRAMS,{
          headers: {
            'Authorization': 'Bearer ' + accessToken
          }}).then(response => {
          
          return response.data
          
        }).catch(error => {
          errorEvaluation(error)
        });
        //console.log(cl)
        applySnapshot(self.dialsPrograms,cl) 
          
    } catch (error) {
      console.error('Nepodařilo se načíst programy!', error)
    } 
    }),

    deleteDialsPrograms: flow(function* fetchProjects() {
      try {
        yield sleep(100)
        self.dialsPrograms.clear()
                
      } catch (error) {
        console.error('Failed to clear dialsPrograms', error)
      }
    }),

    fetchDialsProgramVersions: flow(function* fetchProjects(programId: string) {
      try {

       yield sleep(300)
      
       const accessToken = yield getToken()

        const cl = yield axios.get(Constants.URL_DIALS_PROGRAM_VERSIONS+programId,{
          headers: {
            'Authorization': 'Bearer ' + accessToken
          }}).then(response => {
          
          return response.data
          
        }).catch(error => {
          errorEvaluation(error)
        });
        //console.log(cl)
        applySnapshot(self.dialsProgramVersions,cl) 
          
    } catch (error) {
      console.error('Nepodařilo se načíst verze programu!', error)
    } 
    }),

    deleteDialsProgramVersions: flow(function* fetchProjects() {
      try {
        yield sleep(100)
        self.dialsProgramVersions.clear()
                
      } catch (error) {
        console.error('Failed to clear dialsProgramVersions', error)
      }
    }), 

    fetchDialsLicenseTypes: flow(function* fetchProjects(programId: string) {
      try {

       yield sleep(300)
      
       const accessToken = yield getToken()

        const cl = yield axios.get(Constants.URL_DIALS_LICENSE_TYPES+programId,{
          headers: {
            'Authorization': 'Bearer ' + accessToken
          }}).then(response => {
          
          return response.data
          
        }).catch(error => {
          errorEvaluation(error)
        });
        //console.log(cl)
        applySnapshot(self.dialsLicenseTypes,cl) 
          
    } catch (error) {
      console.error('Nepodařilo se načíst typy licencí!', error)
    } 
    }),

    fetchDialsLicenseTypesAll: flow(function* fetchProjects() {
      try {

       yield sleep(300)
      
       const accessToken = yield getToken()

       self.dialsPrograms.map(program => ({


       }))

       const cl1 = yield axios.get(Constants.URL_DIALS_LICENSE_TYPES+self.dialsPrograms[0].id,{
          headers: {
            'Authorization': 'Bearer ' + accessToken
          }}).then(response => {
          
          return response.data
          
        }).catch(error => {
          errorEvaluation(error)
        });

        const cl2 = yield axios.get(Constants.URL_DIALS_LICENSE_TYPES+self.dialsPrograms[1].id,{
          headers: {
            'Authorization': 'Bearer ' + accessToken
          }}).then(response => {
          
          return response.data
          
        }).catch(error => {
          errorEvaluation(error)
        });

        //console.log(cl)

        const cl = cl1.concat(cl2)

        applySnapshot(self.dialsLicenseTypes,cl) 
          
    } catch (error) {
      console.error('Nepodařilo se načíst typy licencí!', error)
    } 
    }),

    deleteDialsLicenseTypes: flow(function* fetchProjects() {
      try {
        yield sleep(100)
        self.dialsLicenseTypes.clear()
                
      } catch (error) {
        console.error('Failed to clear dialsLicenseTypes', error)
      }
    }),    

    
    fetchDialsServices: flow(function* fetchProjects() {
      try {

       yield sleep(300)
      
       const accessToken = yield getToken()

        const cl = yield axios.get(Constants.URL_DIALS_SERVICES,{
          headers: {
            'Authorization': 'Bearer ' + accessToken
          }}).then(response => {
          
          return response.data
          
        }).catch(error => {
          errorEvaluation(error)
        });
        //console.log(cl)
        applySnapshot(self.dialsServices,cl) 
          
    } catch (error) {
      console.error('Nepodařilo se načíst služby!', error)
    } 
    }),

    deleteDialsServices: flow(function* fetchProjects() {
      try {
        yield sleep(100)
        self.dialsServices.clear()
                
      } catch (error) {
        console.error('Failed to clear dialsServices', error)
      }
    }),

    fetchDialsModules: flow(function* fetchProjects(programId: string) {
      try {

       yield sleep(300)
      
       const accessToken = yield getToken()

        const cl = yield axios.get(Constants.URL_DIALS_MODULES+programId,{
          headers: {
            'Authorization': 'Bearer ' + accessToken
          }}).then(response => {
          
          return response.data
          
        }).catch(error => {
          errorEvaluation(error)
        });
        //console.log(cl)
        applySnapshot(self.dialsModules,cl) 
          
    } catch (error) {
      console.error('Nepodařilo se načíst moduly!', error)
    } 
    }),

    fetchDialsModulesAll: flow(function* fetchProjects() {
      try {

        yield sleep(300)
      
        const accessToken = yield getToken()

        const cl1 = yield axios.get(Constants.URL_DIALS_MODULES+self.dialsPrograms[0].id,{
          headers: {
            'Authorization': 'Bearer ' + accessToken
          }}).then(response => {
          
          return response.data
          
        }).catch(error => {
          errorEvaluation(error)
        });

        const cl2 = yield axios.get(Constants.URL_DIALS_MODULES+self.dialsPrograms[1].id,{
          headers: {
            'Authorization': 'Bearer ' + accessToken
          }}).then(response => {
          
          return response.data
          
        }).catch(error => {
          errorEvaluation(error)
        });

        const cl = cl1.concat(cl2)
        //console.log(cl)
        applySnapshot(self.dialsModules,cl) 
          
    } catch (error) {
      console.error('Nepodařilo se načíst moduly!', error)
    } 
    }),

    deleteDialsModules: flow(function* fetchProjects() {
      try {
        yield sleep(100)
        self.dialsModules.clear()
                
      } catch (error) {
        console.error('Failed to clear dialsModules', error)
      }
    }),

    fetchDialsPriceListsModules: flow(function* fetchProjects(programId: string) {
      try {

       yield sleep(300)
      
       const accessToken = yield getToken()

        const cl = yield axios.get(Constants.URL_DIALS_PRICE_LISTS_MODULES+programId,{
          headers: {
            'Authorization': 'Bearer ' + accessToken
          }}).then(response => {
          
          return response.data
          
        }).catch(error => {
          errorEvaluation(error)
        });
        //console.log(cl)
        applySnapshot(self.dialsPriceListsModules,cl) 
          
    } catch (error) {
      console.error('Nepodařilo se načíst ceník modulů!', error)
    } 
    }),

    fetchDialsPriceListsModulesAll: flow(function* fetchProjects() {
      try {

       yield sleep(300)
      
       const accessToken = yield getToken()

        const cl1 = yield axios.get(Constants.URL_DIALS_PRICE_LISTS_MODULES+self.dialsPrograms[0].id,{
          headers: {
            'Authorization': 'Bearer ' + accessToken
          }}).then(response => {
          
          return response.data
          
        }).catch(error => {
          errorEvaluation(error)
        });

        const cl2 = yield axios.get(Constants.URL_DIALS_PRICE_LISTS_MODULES+self.dialsPrograms[1].id,{
          headers: {
            'Authorization': 'Bearer ' + accessToken
          }}).then(response => {
          
          return response.data
          
        }).catch(error => {
          errorEvaluation(error)
        });

        const cl = cl1.concat(cl2)

        //console.log(cl)
        applySnapshot(self.dialsPriceListsModules,cl) 
          
    } catch (error) {
      console.error('Nepodařilo se načíst ceník modulů!', error)
    } 
    }),

    deleteDialsPriceListsModules: flow(function* fetchProjects() {
      try {
        yield sleep(100)
        self.dialsPriceListsModules.clear()
                
      } catch (error) {
        console.error('Failed to clear dialsPrograms', error)
      }
    }),

    fetchDialsPriceListsServices: flow(function* fetchProjects() {
      try {

       yield sleep(300)
      
       const accessToken = yield getToken()

        const cl = yield axios.get(Constants.URL_DIALS_PRICE_LISTS_SERVICES,{
          headers: {
            'Authorization': 'Bearer ' + accessToken
          }}).then(response => {
          
          return response.data
          
        }).catch(error => {
          errorEvaluation(error)
        });
        //console.log(cl)
        applySnapshot(self.dialsPriceListsServices,cl) 
          
    } catch (error) {
      console.error('Nepodařilo se načíst ceník modulů!', error)
    } 
    }),

    deleteDialsPriceListsServices: flow(function* fetchProjects() {
      try {
        yield sleep(100)
        self.dialsPriceListsServices.clear()
                
      } catch (error) {
        console.error('Failed to clear dialsPrograms', error)
      }
    }),

  }))

export type RootDialsStoreType = Instance<typeof RootDialsStore>
export type DialsCategoriesStoreType = Instance<typeof DialsCategories>
export type DialsProgramsStoreType = Instance<typeof DialsPrograms>
export type DialsProgramVersionsStoreType = Instance<typeof DialsProgramVersions>
export type DialsLicenseTypesStoreType = Instance<typeof DialsLicenseTypes>
export type DialsServicesStoreType = Instance<typeof DialsServices>
export type DialsModulesStoreType = Instance<typeof DialsModules>
export type DialsPriceListsModulesStoreType = Instance<typeof DialsPriceListsModules>