import { types } from 'mobx-state-tree'


export const CompetitionItemsWithCountsList = types
  .model({
    idCompetitionItems: types.optional(types.string, ''),
    name: types.maybeNull(types.string),
    type: types.maybeNull(types.string),
    count: types.optional(types.number, 0),
    order: types.optional(types.number, 0),
  })
  .views(self => ({
    
  }))
  .actions(self => ({
    

  }))
