import React, {useState} from 'react'
import { Link } from 'react-router-dom'
import { ROUTES } from '../../../Routes'

import HomeInfo from '../../../parts/homeInfo/HomeInfo.parts'
import ErrorAlert from '../../../parts/errorAlert/ErrorAlertWithoutLogout.part'

import {Container, Alert, Spinner, Image} from 'react-bootstrap'

import axios from 'axios';

import { ConfirmRegistrationUrlParams } from '../../../Routes'
import { useParams } from 'react-router'

import * as Constants from '../../../Constants/Constants';

import { useMount } from '../../../helpers/lifecycle-hooks'

import Loader from "react-loader"

function ConfirmRegistration(props:any) {
  // Získání tokenu z URL adresy
  const { idMembers } = useParams<ConfirmRegistrationUrlParams>()
  const { secureCode } = useParams<ConfirmRegistrationUrlParams>()
  
  // Nastavení stavů pro zobrazení
  const [loaded, setLoaded] = useState(true)
  const [error, setError] = useState(null)
  const [show, setShow] = useState(true)
  const [showTrue, setShowTrue] = useState(false);
  
  // Kontrola zda se načetla URL BE
  if (sessionStorage.getItem('URL') === null) {
    window.location.reload();
  }
  
  useMount(() => {
  // Asynchronní volání požadavku
  (async () => {
    setLoaded(false) // Nastavení pro Loader, že není strana načtena
    await axios.post(Constants.URL_REGISTRATION_CONFIRM, { idMembers: idMembers, secureCode: secureCode }
      ).then(response => {
        // Pokud OK, zobrazit potvrzovací hlášení a po 10s přepnout na loginpage
        setShow(false)
        setShowTrue(true)
        setTimeout(() => {
          //props.history.push('/login');  
        }, 10000);
      }).catch(error => {
        setShow(false);
        const errorMessage:any = (<ErrorAlert errorData={error} />)
        setError(errorMessage)
      });
    setLoaded(true)  

  })();
})

  
  return (
    <div className="App">
      <Loader 
        loaded={loaded}
        lines={13}
        length={20}
        width={10}
        radius={30}
        corners={1}
        rotate={0}
        direction={1}
        color="#000"
        speed={1}
        trail={60}
        shadow={true}
        hwaccel={true}
        className="spinner"
        zIndex={2e9}
        top="50%"
        left="50%"
        scale={1.0}
      > 
        <main>
          <Container fluid className="nopadding">
            <div className="row no-gutter">
              <div className="d-none d-md-flex col-md-4 col-lg-6 bg-image">
                <div className="login d-flex align-items-center py-5">
                  <Container className="login-text-white login-container">
                    <div className="row">
                      <div className="mx-auto">
                        
                      </div>
                    </div>
                  
                  </Container>
                </div>  
              </div>
              <div className="col-md-8 col-lg-6">
                <div className="login d-flex align-items-center py-5">
                  <div className="container">
                    <div className="row">
                      <div className="col-md-9 col-lg-8 mx-auto">
                        <h2 className="login-text-vertical-center"><Image src="images/cpadu-small.png" width={130} className="login-logo" /> Informační systém</h2>
                        <hr />
                        <br />
                        {error}
                        <Alert variant="warning" show={show}><Spinner animation="border" variant="primary" />&nbsp;&nbsp;Dokončujeme registraci...</Alert>
                        <Alert variant="success" show={showTrue}>
                          <Alert.Heading>Registrace dokončena!</Alert.Heading>
                          <p>Dokončení registrace proběhlo úspěšně.</p>
                          <hr />
                          <p className="mb-0">
                            
                          </p>
                        </Alert>    
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Container>
    
        </main>
      </Loader>
    </div>
  );
}



export default ConfirmRegistration