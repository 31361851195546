import { types } from 'mobx-state-tree'



export const MembersSUTimport = types
  .model({
    id: types.optional(types.string, ''),
    surName: types.maybeNull(types.string),
    firstName: types.maybeNull(types.string),
    
    club: types.maybeNull(types.string),
    rodneCislo: types.maybeNull(types.string),
    year: types.optional(types.number, 0),
  })
  .views(self => ({
    
  }))
  .actions(self => ({
    

  }))
