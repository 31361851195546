import React, {useState}  from 'react'
import Loader from "react-loader"
import { ROUTES } from '../../Routes'
import { NavLink } from 'react-router-dom'

import TopMenu from '../../parts/TopMenu/TopMenu.parts'
import SideMenu from '../../parts/TopMenu/SideMenu.parts'

import FormControl from 'react-bootstrap/FormControl';
import FormGroup from 'react-bootstrap/FormGroup';
import FormLabel from 'react-bootstrap/FormLabel'
import { Formik } from 'formik'
import * as Yup from 'yup'

import MembersUpdate from './components/Members.Update.part'
import MembersDetail from './components/Members.Detail.part'
import MembersMembershipToUpdate from './components/Members.MembershipTo.Update.part'
import MembersMedicalExaminationToUpdate from './components/Members.MedicalExaminationTo.Update.part'
import MembersInsert from './components/Members.Insert.part'

import { Container, CardDeck, Alert, Row, Col, Table, Badge, Form } from 'react-bootstrap'

import { useMount } from '../../helpers/lifecycle-hooks'
// eslint-disable-next-line
import { useStore,useDialsStore, useUpgradeStore } from '../../store'
import { observer } from 'mobx-react'
import { getToken, handleMenuContent } from '../../Utils/Common'

import moment from 'moment'

import Pagination from 'react-js-pagination'

function MembersCAL(props:any) {
  // Nastavení proměnné pro Loader
  const [loaded, setLoaded] = useState(true);
  const [loading, setLoading] = useState(false)
  const [page, setPage] = useState(1)
  // Nastavení práv zda jsme Ježci
  
  const [filterName, setFilterName] = useState(!(localStorage.getItem('memberCALFilterName') === undefined || localStorage.getItem('memberCALFilterName') === null)  ? localStorage.getItem('memberCALFilterName') : '');
  const [filterId, setFilterId] = useState(!(localStorage.getItem('memberCALFilterId') === undefined || localStorage.getItem('memberCALFilterId') === null)  ? localStorage.getItem('memberCALFilterId') : '');

  // Načtení Store
  const rootStore = useStore()
  //const rootDialsStore = useDialsStore()
  //const rootUpgradeStore = useUpgradeStore()

  //rootUpgradeStore.fetchUpgradeOffersScenarios()
  //const customer = rootStore.getOpenedCustomerById(localStorage.getItem('openedCustomerId')!)
  
  // Nastavení pro stránkování
  const itemsPerPage = 25

  const indexOfLastItem = page * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;

  // Při načtení stránky 
  useMount(() => {
    (async () => {  
    setLoaded(false) // Nastavení pro Loader, že není strana načtena
    window.scrollTo(0, 0)
    
    // Kontrola přihlášení  
    //const aToken = await getToken()
    if (localStorage.getItem('isLogged') !== 'is') {   
      sessionStorage.setItem('automaticLogout', '1')
      props.history.push('/logout')
    }
    else {
      //Načtení souvisejících číselníků podle práv
      


      //Pokud je vyvolaná F5
      if (window.performance) {
        if (performance.navigation.type > 0 || sessionStorage.getItem('isIn') === null) {
          await rootStore.fetchLoggedUsers(localStorage.getItem('email')!, localStorage.getItem('PIN')!)
          //await rootStore.fetchCustomerOpened()
          console.log( "This page is reloaded" )
          sessionStorage.setItem('isIn', '1')
        } else {
          
        }
      } 
      //Konec sekce F5

      await rootStore.fetchDancerListCAL(rootStore.loggedUsers[0]?.idUsers, localStorage.getItem('PIN')!)
      await rootStore.fetchDialsStyles()
      await rootStore.fetchDialsLevels()
      await rootStore.fetchCountries()
      //Pokud mám práva tak vždy načítám další položky 
      setLoaded(true) // Nastavení pro Loader, že je strana načtena

      

    }
    /*if (rootStore.loggedUsers[0] === undefined) {   
      sessionStorage.setItem('automaticLogout', '1')
      props.history.push('/logout')
    }    */ 
    setLoaded(true) // Nastavení pro Loader, že je strana načtena
    })() 
  })

  //Počet záznamů
  const countData:number = rootStore.dancerListCAL
  .filter(data => {if (filterName === '') return data; else return data.searchString!.toLowerCase().indexOf(filterName!.toLowerCase()) >= 0  })
  .filter(data => {if (filterId === '') return data; else return data.searchStringId!.toLowerCase().indexOf(filterId!.toLowerCase()) >= 0  }).length

    // Seznam zákazníků do řádků tabulky
    const dancerListDataTable = rootStore.dancerListCAL
    .filter(data => {if (filterName === '') return data; else return data.searchString!.toLowerCase().indexOf(filterName!.toLowerCase()) >= 0  })
    .filter(data => {if (filterId === '') return data; else return data.searchStringId!.toLowerCase().indexOf(filterId!.toLowerCase()) >= 0  })
    .slice(indexOfFirstItem, indexOfLastItem)
  
    .map(dancerList => (
      <tr key={dancerList.id}>
        <td><strong>{dancerList.id}</strong></td>
        <td>{dancerList.soloDuo ? 'Solo':''}{dancerList.couples ? dancerList.soloDuo ? ', Páry':'Páry':''}  </td>
        <td>{dancerList.surName}</td>
        <td>{dancerList.firstName}</td>
        <td>{moment(dancerList.birthdate).format("YYYY")}</td>
        <td>{dancerList.club}</td>
        <td>
          {dancerList.memberAfterMedicalExamination ? <Badge variant="danger">Prohlídka</Badge> : <Badge variant="success">Prohlídka</Badge>}
          {dancerList.lastPaidYear < new Date().getFullYear() ? <Badge style={{marginLeft: '5px'}} variant="danger">Nezaplaceno</Badge> : <Badge style={{marginLeft: '5px'}} variant="success">Zaplaceno</Badge>}
          
        </td>
        <td></td>        
        <td>
          <MembersMedicalExaminationToUpdate id={dancerList.id} />
          <MembersMembershipToUpdate id={dancerList.id} />
          <MembersDetail id={dancerList.id} />
          <MembersUpdate id={dancerList.id} />
        </td>
      </tr>
    ))

    const handlePageChange = (pageNumber:number) => {
      (async () => { 
        setLoading(true);

        sessionStorage.setItem('membersCALListSearchPage', String(pageNumber))
        // Předat hledanou hodnotu action pro načtení zákazníků
        setPage(pageNumber)
        //await rootInvoicesStore.fetchInvoicesList(pageNumber, props.invoiceType)
        setLoading(false);  
      })()
    }

    const schemaUpdate = Yup.object().shape({
  
    })

  return (
    <div className="App">
      
        <TopMenu />

        <div id="layoutSidenav">
          <SideMenu />
          <div id="layoutSidenav_content" onClick={handleMenuContent}>
            <main>
            <Loader 
              loaded={loaded}
              lines={13}
              length={20}
              width={10}
              radius={30}
              corners={1}
              rotate={0}
              direction={1}
              color="#000"
              speed={1}
              trail={60}
              shadow={true}
              hwaccel={true}
              className="spinner"
              zIndex={2e9}
              top="50%"
              left="50%"
              scale={1.0}
            >
              
              <Container fluid>
                <br />
                <h4>Seznam sportovců</h4>
                <br />
                <Alert variant="info">
                  <Formik
                    validationSchema={schemaUpdate}
                    initialValues={{ 
                      memberCALId: !(localStorage.getItem('memberCALFilterId') === undefined || localStorage.getItem('memberCALFilterId') === null)  ? localStorage.getItem('memberCALFilterId') : '',
                      memberCALName: !(localStorage.getItem('memberCALFilterName') === undefined || localStorage.getItem('memberCALFilterName') === null)  ? localStorage.getItem('memberCALFilterName') : '',
                      
                    }}
                    onSubmit={(values, { setSubmitting }) => {
                      setTimeout(async () => {
                        //handleInsert(values.idLecturers!)
                        await setLoading(true)
                        await localStorage.setItem('memberCALFilterName', values.memberCALName!)
                        await localStorage.setItem('memberCALFilterId', values.memberCALId!)

                        await setFilterName(values.memberCALName!)
                        await setFilterId(values.memberCALId!)

                        await sessionStorage.setItem('membersCALListSearchPage', String(1))
                
                        await setPage(1)
                        await setLoading(false)

                        setSubmitting(false);
                      }, 100);
                    }}
                  >
                    {({ 
                      handleSubmit,
                      handleChange,
                    
                      values,
                      touched,
                      isValid,
                      errors,
                      isSubmitting }) => (
                      <Form onSubmit={handleSubmit}>
                        <Form.Row>
                        
                        <FormGroup controlId="memberCALId" style={{paddingLeft: '10px', marginBottom: '0px'}}>
                        <Form.Row>
                        <FormLabel column lg={3}>ID/RČ</FormLabel>
                            <Col>
                              <FormControl 
                                type="text"
                                name="memberCALId"
                                value={values.memberCALId!}
                                onChange={async (e:any) => { await handleChange(e); await handleSubmit(e); }}
                                //isValid={touched.educationName && !errors.educationName} 
                                //isInvalid={!!errors.educationName}
                                placeholder='hledaný text'
                                autoFocus
                              />
                              
                              <Form.Control.Feedback type="invalid">
                                {errors.memberCALId}
                              </Form.Control.Feedback>
                            </Col>
                            </Form.Row>
                        </FormGroup> 

                        <FormGroup controlId="memberCALName" style={{paddingLeft: '40px', marginBottom: '0px'}}>
                        <Form.Row>
                        <FormLabel column lg={4}>Hledaný text</FormLabel>
                            <Col>
                              <FormControl 
                                type="text"
                                name="memberCALName"
                                value={values.memberCALName!}
                                onChange={async (e:any) => { await handleChange(e); await handleSubmit(e); }}
                                //isValid={touched.educationName && !errors.educationName} 
                                //isInvalid={!!errors.educationName}
                                placeholder='hledaný text'
                                autoFocus
                              />
                              
                              <Form.Control.Feedback type="invalid">
                                {errors.memberCALName}
                              </Form.Control.Feedback>
                            </Col>
                            </Form.Row>
                        </FormGroup> 

                        

                        

                        </Form.Row>
                        
                      </Form>
                    )}
                  </Formik>  
                  
                </Alert>
                <br />
                
                <Table responsive hover>
                      <thead>
                        <tr className="jsw-table-head">
                          <th className="jsw-table-head row-slim">ID</th>
                          <th className="jsw-table-head row-slim">Typ</th>
                          <th className="jsw-table-head">Příjmení</th>
                          <th className="jsw-table-head">Jméno</th>
                          <th className="jsw-table-head">Rok</th>
                          <th className="jsw-table-head">Klub</th>
                          <th className="jsw-table-head"></th>
                          <th className="jsw-table-head"></th>
                          <th className="jsw-table-head">Akce</th>
                        </tr>
                      </thead>
                      <tbody>
                        {dancerListDataTable}
                      </tbody>
                    </Table> 

                    <br />   
                    {!loading &&
                    <Pagination
                      itemClass="page-item"
                      linkClass="page-link"
                      activePage={Number(sessionStorage.getItem('membersCALListSearchPage')) === 0 ? 1:Number(sessionStorage.getItem('membersCALListSearchPage')) }
                      itemsCountPerPage={itemsPerPage}
                      totalItemsCount={countData}
                      pageRangeDisplayed={10}
                      onChange={handlePageChange.bind(1)}
                      /> }

                    {countData === 0 ? <div>Seznam tanečníků neobsahuje žádné záznamy.<br /><br /></div> : ''}

                <Row>
                  <Col style={{minWidth:'300px', marginTop: '25px'}}>
                    
                    
                  </Col>
                  <Col style={{minWidth:'300px', marginTop: '25px'}}>
                    
                  </Col>
                </Row>
                
                

                <br /><br />
              </Container>
              </Loader>
            </main>
          </div>
        </div>
      
    </div> 
  );
}

export default observer(MembersCAL)
