import { types } from 'mobx-state-tree'

const Program = types.model('Program',{
  id: types.maybeNull(types.string),
  name: types.maybeNull(types.string),
  code: types.maybeNull(types.string)
})

const ProgramVersion = types.model('ProgramVersion',{
  id: types.maybeNull(types.string),
  name: types.maybeNull(types.string),
  version: types.maybeNull(types.number),
  programId: types.maybeNull(types.string)
})

const LicenseType = types.model('LicenseType',{
  id: types.optional(types.string,''),
  name: types.maybeNull(types.string),
  code: types.maybeNull(types.string),
  programId: types.maybeNull(types.string)
})

const Module = types.model('Module',{
  id: types.optional(types.string, ''),
  name: types.maybeNull(types.string),
  code: types.maybeNull(types.string),
  programId: types.maybeNull(types.string)
})

const Modules = types.model('Modules',{
  count: types.maybeNull(types.number),
  module: types.frozen(Module)
})

export const CalculatorsUpgradeCustomerLicenses = types
  .model({
    id: types.optional(types.string, ''),
    count: types.maybeNull(types.number),
    isActive: types.optional(types.boolean, false),
    status: types.maybeNull(types.string),

    program: types.frozen(Program),
    programVersion: types.frozen(ProgramVersion),

    licenseType: types.frozen(LicenseType),

    modules: types.array(Modules),
    
    
  })
  .views(self => ({
    
  }))
  .actions(self => ({
    

  }))
