import { types } from 'mobx-state-tree'
import { start } from 'repl'

const Applications = types.model('Applications',{
    idApplications: types.optional(types.string, ''),
    idCompetitions: types.optional(types.string, ''),
    firstName1: types.maybeNull(types.string),
    surName1: types.maybeNull(types.string),
    firstName2: types.maybeNull(types.string),
    surName2: types.maybeNull(types.string),
    firstName3: types.maybeNull(types.string),
    surName3: types.maybeNull(types.string),
    club: types.maybeNull(types.string),
    country: types.maybeNull(types.string),
    date1: types.maybeNull(types.string),
    date2: types.maybeNull(types.string),
    date3: types.maybeNull(types.string),
    phone: types.maybeNull(types.string),
    email: types.maybeNull(types.string),
    secureCode: types.maybeNull(types.string),
    type: types.maybeNull(types.string),
    startno: types.optional(types.number, 0),
    startno2: types.optional(types.string, ''),
    variableName: types.maybeNull(types.string),
    musicFileId: types.maybeNull(types.string),
})
.views(self => ({
  get getFileNameExt() {
    let fileName = self.musicFileId
    let extension = ''
    if (!(fileName === null || fileName === '')) {
      //let ext = filename.split('.').pop()
      extension = fileName.slice((fileName.lastIndexOf('.') - 1 >>> 0) + 2).toLowerCase();  // získání přípony souboru
    }
    return extension
  },

  get variableNameNormalized() {
    return self.variableName !== null ? self.variableName
    .normalize('NFD') // Normalizuje text, aby rozložil kombinované znaky
    .replace(/[\u0300-\u036f]/g, '') // Odstraní diakritiku
    .replace(/\s+/g, '-') // Nahradí všechny mezery pomlčkami

    : ''
    //.toLowerCase(); // Převod na malá písmena 
  },

  get startnoToDisplay4digits() {
    return self.startno.toString().padStart(4, '0')
  },


}))

const CompetitionItems = types.model('CompetitionItems',{
  idCompetitionItems: types.optional(types.string, ''),
  idCompetitions: types.optional(types.string, ''),
  idGroup: types.maybeNull(types.string),
  name: types.maybeNull(types.string),
  nameEN: types.maybeNull(types.string),
  name2: types.maybeNull(types.string),
  type: types.maybeNull(types.string),
  style: types.maybeNull(types.string),
  level: types.maybeNull(types.string),
  allowVariableName: types.maybeNull(types.string),
  hdid: types.maybeNull(types.string),
  applications: types.array(Applications),
})

export const CompetitionAppList = types
  .model({
    idCompetitions: types.optional(types.string, ''),
    date: types.maybeNull(types.string),
    dateTo: types.maybeNull(types.string),
    name: types.maybeNull(types.string),
    description: types.maybeNull(types.string),
    logo: types.maybeNull(types.string),
    applicationsDate: types.maybeNull(types.string),
    allowSolos: types.maybeNull(types.string),
    allowDuets: types.maybeNull(types.string),
    allowTeams: types.maybeNull(types.string),
    allowProams: types.maybeNull(types.string),
    allowCouples: types.maybeNull(types.string),
    allowPara: types.maybeNull(types.string),  
    showNames: types.maybeNull(types.string),
    password: types.maybeNull(types.string),
    labelSolo: types.optional(types.string,''),
    labelSoloEn: types.optional(types.string,''),
    labelDuo: types.optional(types.string,''),
    labelDuoEn: types.optional(types.string,''),
    labelCouple: types.optional(types.string,''),
    labelCoupleEn: types.optional(types.string,''),
    labelTeam: types.optional(types.string,''),
    labelTeamEn: types.optional(types.string,''),
    showAppDetailCompetitions: types.optional(types.string,'0'),
    competitionItems: types.array(CompetitionItems)

  })
  .views(self => ({
    
  }))
  .actions(self => ({
    

  }))
