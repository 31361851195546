import { types } from 'mobx-state-tree'

const Levels = types.model('Levels',{
  id: types.optional(types.string, ''),
  idLevels: types.maybeNull(types.string),
  idDialsCompetitions: types.maybeNull(types.string)
})

export const DialsCompetitions = types
  .model({
    idDialsCompetitions: types.optional(types.string, ''),
    idAgeCategories: types.maybeNull(types.string),
    idCompetitionTypes: types.maybeNull(types.string),
    idStyles: types.maybeNull(types.string),
    idCompetitionVariants: types.maybeNull(types.string),
    dialsCompetitionName: types.maybeNull(types.string),
    dialsCompetitionNameEN: types.maybeNull(types.string),
    dances: types.maybeNull(types.string),
    active: types.maybeNull(types.string),
    levels: types.array(Levels)
  })
  .views(self => ({
    
  }))
  .actions(self => ({
    

  }))
