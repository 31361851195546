import { authFetch, logout } from '../authProvider'
import moment from 'moment'
import { sleep } from '../helpers/sleep';

// return the user data from the session storage
export const getUser = () => {
  const userStr = localStorage.getItem('user');
  if (userStr) return JSON.parse(userStr);
  else return null;
}

const jwtExp = (token?: any): number | null => {
  if (!(typeof token === 'string')) {
      return null;
  }

  const split = token.split('.');

  if (split.length < 2) {
      return null;
  }

  try {
      const jwt = JSON.parse(atob(token.split('.')[1]));
      if (jwt && jwt.exp && Number.isFinite(jwt.exp)) {
          return jwt.exp * 1000;
      } else {
          return null;
      }
  } catch (e) {
      return null;
  }
};

const getExpire = (token: string | null) => {
  if (!token) {
      return null;
  }

  return jwtExp(token);
};

const isExpired = (exp: number | null) => {
  if (!exp) {
      return false;
  }

  return Date.now() > exp;
};

// Test na Expiraci tokenu
export const checkIsTokenExpired = () => {
  const localStorageDataTest = localStorage.getItem('REACT_TOKEN_AUTH_KEY');
  const atokenTest = ((JSON.parse(localStorageDataTest!)) )
  //console.log('Expirace')
  //console.log(atokenTest)
  if (atokenTest === null)
    { return true }
  else { return isExpired(getExpire(atokenTest.accessToken)!) }  
}

// return the token from the session storage
export const getToken = async () => {
  if (localStorage.getItem('REACT_TOKEN_AUTH_KEY') !== null) {
    if (checkIsTokenExpired()) {
      await authFetch('helpers/fData.json')
      //const temp = await authFetch('helpers/fData.json')
      //console.log(temp)  
    }

    if (localStorage.getItem('REACT_TOKEN_AUTH_KEY') !== null) {
    const localStorageData = localStorage.getItem('REACT_TOKEN_AUTH_KEY');
    const atoken = ((JSON.parse(localStorageData!)) )
    //console.log(atoken.accessToken)
    return atoken.accessToken;
    }
    else {
      return null  
    }
  }
  else {
    return null
  }
    
  
}

export const checkVersionData = async () => {
  console.log('D1')
  sessionStorage.setItem('versionFromJSON', '0.0.0')
  //let version:string = '0.0.0'
  await  fetch('appsettings.json?timestamp='+moment(new Date()).format('YYYYMMDDHHmmssSS'))
  .then((response) => response.json())
  .then((data) => {console.log(data.version); sessionStorage.setItem('versionFromJSON',data.version)});

}

export const checkVersion = async () => {
  console.log('A1')
  //let version:string = ''
  //const version =await  fetch('appsettings.json?timestamp='+moment(new Date()).format('YYYYMMDDHHmmssSS'))
  //.then((response) => response.json())
  //.then((data) => {console.log(data.version);return data.version});

  //let version:string = '0.0.0'

  await checkVersionData()

  console.log('A2')

  /*const version = await axios.get('appsettings.json?timestamp='+moment(new Date()).format('YYYYMMDDHHmmssSS')).then(response => {
    console.log('A2')
    return response.data.version
    //if (sessionStorage.getItem('version') !==  response.data.version) { window.location.reload() }
    //sessionStorage.setItem('version', response.data.version)
  }).catch(error => {
    console.log('A3')
    // eslint-disable-next-line
    window.location.href = window.location.href; window.location.reload();

  });*/
  //console.log('A4')
  await console.log(sessionStorage.getItem('versionFromJSON'))
  
  //await sleep(10000)
  if (sessionStorage.getItem('version') !== sessionStorage.getItem('versionFromJSON')) { 
    await console.log('Restartuji FE')
    
    
    //this.document.reload(true)
    // eslint-disable-next-line
    window.location.href = window.location.href; 
    window.location.reload(); 
    await sleep(10000)
     }
  return null  
  
}

// remove the token and user from the session storage
export const removeUserSession = () => {

  localStorage.removeItem('token')
  localStorage.removeItem('user')
  localStorage.removeItem('username')
  localStorage.removeItem('isLogged')
  localStorage.removeItem('PIN')
  localStorage.removeItem('customersTotalCount')
  localStorage.removeItem('customersTotalCountInit')
  localStorage.removeItem('customersTooMany')
  localStorage.removeItem('customersTooManyInit')
  localStorage.removeItem('customersFirstId')
  localStorage.removeItem('openedCustomerId')

  localStorage.removeItem('BigHedgehog')
  localStorage.removeItem('BigHedgehogBefore')
  localStorage.removeItem('SmallHedgehog')
  localStorage.removeItem('SmallHedgehogBefore')
  localStorage.removeItem('BigUser')
  localStorage.removeItem('BigUserBefore')
  localStorage.removeItem('SmallUser')

  localStorage.removeItem('invitesTotalCount')
  localStorage.removeItem('isLogged')
  sessionStorage.removeItem('isIn')
  sessionStorage.removeItem('customersSearchValue')
  sessionStorage.removeItem('customersSearchTypeValue')
  sessionStorage.removeItem('customersSearchPage')

  localStorage.removeItem('lastDatafilter')
  localStorage.removeItem('lastDatafilterType')
  localStorage.removeItem('lastDatafilterTotalCount')
  localStorage.removeItem('lastDatafilterOfferTotalCount')
  localStorage.removeItem('openedFromOverviews')

  sessionStorage.removeItem('selectionDetailSearchValue')
  sessionStorage.removeItem('selectionDetailSearchTypeValue')
  sessionStorage.removeItem('selectionDetailPage')

  sessionStorage.removeItem('selectionDetailOfferSearchTextValue')
  sessionStorage.removeItem('selectionDetailOfferSearchTypeValue')
  sessionStorage.removeItem('selectionDetailOfferSearchScenarioValue')
  sessionStorage.removeItem('selectionDetailOfferPage')

  sessionStorage.removeItem('historySearchTypeValue')
  sessionStorage.removeItem('historySearchOperationTypeValue')
  sessionStorage.removeItem('historySearchPage')

  sessionStorage.removeItem('emailsSentSearchValue')
  sessionStorage.removeItem('emailsSentSearchTypeValue')
  sessionStorage.removeItem('emailsSentSearchTIDValue')
  sessionStorage.removeItem('emailsSentSearchPage')
  localStorage.removeItem('customerEmailsSentTotalCount')
  localStorage.removeItem('lastTemplatesSearchTotalCount')
  

  localStorage.setItem('userNameMenu', 'nepřihlášen')

}


// set the token and user from the local storage
export const setUserSession = ( user:any) => {
  
  localStorage.setItem('user', JSON.stringify(user))
  //console.log(localStorage.getItem('user'))
  //localStorage.setItem('userNameMenu', user.firstName+' '+user.surName)
  localStorage.setItem('permissions', user.permissions)
  localStorage.setItem('email', user.login)
  
  localStorage.setItem('isLogged', 'is')
}

// set the token and user from the local storage
export const setUserSessionAdj = ( user:any) => {
  
  localStorage.setItem('user', JSON.stringify(user))
  //console.log(localStorage.getItem('user'))
  //localStorage.setItem('userNameMenu', user.firstName+' '+user.surName)

  localStorage.removeItem('SPRAVCE')
  localStorage.removeItem('POROTCE')
  localStorage.removeItem('DOZOR')
  localStorage.removeItem('PROJEKCE')
  
  if (user.ROLE === "S") {localStorage.setItem('SPRAVCE', '1')}
  if (user.ROLE === "P") {localStorage.setItem('POROTCE', '1')}

  if (user.ROLE === "D") {localStorage.setItem('DOZOR', '1')}
  if (user.ROLE === "U") {localStorage.setItem('PROJEKCE', '1')}
  
  localStorage.setItem('isLogged', 'is')
}

export const setUserRoles = (roles:any) => {
  
  localStorage.removeItem('BigHedgehog')
  localStorage.removeItem('BigHedgehogBefore')
  localStorage.removeItem('SmallHedgehog')
  localStorage.removeItem('SmallHedgehogBefore')
  localStorage.removeItem('BigUser')
  localStorage.removeItem('BigUserBefore')
  localStorage.removeItem('SmallUser')

  if (roles.includes("BigHedgehog")) {localStorage.setItem('BigHedgehog', '1')}
  if (roles.includes("SmallHedgehog")) {localStorage.setItem('SmallHedgehog', '1')}

  if (roles.includes("BigUser")) {localStorage.setItem('BigUser', '1')}
  if (roles.includes("SmallUser")) {localStorage.setItem('SmallUser', '1')}

  
}

export const handleMenuContent = () => {
  if (document.body.classList.contains('sb-sidenav-toggled') && window.innerWidth < 992) {
    document.body.classList.toggle('sb-sidenav-toggled');
  }
  
}



export const errorEvaluation = (errorData:any) => {
  if (errorData.response !== undefined && errorData.response.data.statusCode === 403) 
  {
    sessionStorage.setItem('automaticLogout', '2')
    removeUserSession()
    logout()
  }
  if (errorData.response !== undefined && errorData.response.data.statusCode === 404) 
  {
    sessionStorage.setItem('automaticLogout', '2')
    removeUserSession()
    logout()
  }  
  if (errorData.response !== undefined && errorData.response.data.statusCode === 500) 
  {
    sessionStorage.setItem('automaticLogout', '2')
    removeUserSession()
    logout()
  }
  if ( !!errorData.isAxiosError && !errorData.response ) {
    sessionStorage.setItem('automaticLogout', '3')
    removeUserSession()
    logout()
  }
}

export const getModuleOrder = (moduleCode:string) => {
  let order:number = 99
  if (moduleCode === "Y") order = 1
  if (moduleCode === "K") order = 2
  if (moduleCode === "U") order = 3
  if (moduleCode === "F") order = 4
  if (moduleCode === "M") order = 5
  if (moduleCode === "S") order = 6
  if (moduleCode === "P") order = 7
  if (moduleCode === "E") order = 8
  if (moduleCode === "J") order = 9
  if (moduleCode === "A") order = 10
  return order
}

export const getPriceListDetailOrder = (priceType:string, isFirst:boolean) => {
  let order:number = 99
  //console.log(priceType)
  //console.log(isFirst)
  if (priceType === "P" && isFirst)  order = 1
  if (priceType === "P" && !isFirst) order = 2
  if (priceType === "F" && isFirst)  order = 3
  if (priceType === "F" && !isFirst) order = 4
  if (priceType === "S" && isFirst)  order = 5
  if (priceType === "S" && !isFirst) order = 6
  if (priceType === "E" && isFirst)  order = 7
  if (priceType === "E" && !isFirst) order = 8  
 
  return order
}

export const getNumberFormated = (price:number) => {
  let tisice:number = Math.floor(price/1000)
  //console.log(tisice)
  //console.log(price-(tisice*1000))
  let stovky:number = Math.floor(price-(tisice*1000))
  let halere:number = price-Math.floor(price)

  let tisiceTxtPart1:string = tisice !== 0 ? tisice.toString() : ''
  let tisiceTxtPart2:string = tisice !== 0 ? '.' : ''
  let textove:string = tisiceTxtPart1+tisiceTxtPart2+stovky.toString()+","+halere.toString()

  if (halere < 10) {
    textove = tisiceTxtPart1+tisiceTxtPart2+stovky.toString()+",0"+halere.toString()
  }
  
  return textove
}