import { useRef, useEffect, DependencyList, EffectCallback } from 'react'

export const useDidUpdate = (
  callback: EffectCallback,
  dependencies?: DependencyList
) => {
  const hasMount = useRef(false)

  useEffect(() => {
    if (hasMount.current) {
      return callback()
    }
    hasMount.current = true
    // eslint-disable-next-line
  }, dependencies)
}

export const useMount = (callback: EffectCallback) => {
  useEffect(callback, [])
}
