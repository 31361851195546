import { getLoadableComponent } from "../sharedComponents/LoadableComponent/LoadableComponent"

import AccountsRegistration from './accounts/registration/Accounts.Registration.page'
import AccountsRegistrationCAL from './accounts/registrationCAL/Accounts.RegistrationCAL.page'

import AccountsConfirmRegistration from './accounts/confirmRegistration/Accounts.ConfirmRegistration.page'
import AccountsConfirmRegistrationCAL from './accounts/confirmRegistration/Accounts.ConfirmRegistrationCAL.page'

import MemberCALInfo from './accounts/memberInfo/Accounts.MemberCALInfo.page'
import MemberCPADUInfo from './accounts/memberInfo/Accounts.MemberCPADUInfo.page'

import BankAccountTransfersCAL from "./bankAccountTransfersCAL/BankAccountTransfersCAL.page"
import BankAccountTransfersCPADU from "./bankAccountTransfersCPADU/BankAccountTransfersCPADU.page"

import MembersCAL from './membersCAL/MembersCAL.page'

export const Pages = {
  Home: getLoadableComponent(() => import('./home/Home.page')),
  
  Competition: getLoadableComponent(() => import('./competition/Competition.page')),
  CompetitionSchedule: getLoadableComponent(() => import('./competitionSchedule/CompetitionSchedule.page')),
  Application: getLoadableComponent(() => import('./application/Application.page')),
  ApplicationMaster: getLoadableComponent(() => import('./application/ApplicationMaster.page')),
  ApplicationListByCompetitions: getLoadableComponent(() => import('./applicationList/ApplicationListByCompetitions.page')),
  ApplicationListByCompetitionsMaster: getLoadableComponent(() => import('./applicationList/ApplicationListByCompetitionsMaster.page')),
  ApplicationListByPeople: getLoadableComponent(() => import('./applicationList/ApplicationListByPeople.page')),

  /* ACCOUNTS */
  AccountsLogin: getLoadableComponent(() => import('./accounts/login/Accounts.Login.page')),
  AccountsLogout: getLoadableComponent(() => import('./accounts/logout/Accounts.Logout.page')),
  AccountsMyAccount: getLoadableComponent(() => import('./accounts/myAccount/Accounts.MyAccount.page')),
  //AccountsRegistration: getLoadableComponent(() => import('./accounts/registration/Accounts.Registration.page')),
  AccountsRegistration: AccountsRegistration,
  AccountsRegistrationCAL: AccountsRegistrationCAL,
  //AccountsConfirmRegistration: getLoadableComponent(() => import('./accounts/confirmRegistration/Accounts.ConfirmRegistration.page')),
  AccountsConfirmRegistration: AccountsConfirmRegistration,
  AccountsConfirmRegistrationCAL: AccountsConfirmRegistrationCAL,

  Dashboard: getLoadableComponent(() => import('./dashboard/Dashboard.page')),
  Members: getLoadableComponent(() => import('./members/Members.page')),
  MembersTempImport: getLoadableComponent(() => import('./members/MembersTempImport.page')),

  MembersCAL: MembersCAL,

  MemberCALInfo: MemberCALInfo,
  MemberCPADUInfo: MemberCPADUInfo,

  BankAccountTransfersCAL: BankAccountTransfersCAL,
  BankAccountTransfersCPADU: BankAccountTransfersCPADU,

  DialsClasses: getLoadableComponent(() => import('./dials/classes/Dials.Classes.page')),
  DialsStyles: getLoadableComponent(() => import('./dials/styles/Dials.Styles.page')),
  DialsLevels: getLoadableComponent(() => import('./dials/levels/Dials.Levels.page')),
  DialsCompetitionTypes: getLoadableComponent(() => import('./dials/competitionTypes/Dials.CompetitionTypes.page')),
  DialsCompetitionVariants: getLoadableComponent(() => import('./dials/competitionVariants/Dials.CompetitionVariants.page')),
  DialsAgeCategories: getLoadableComponent(() => import('./dials/ageCategories/Dials.AgeCategories.page')),
  DialsCompetitions: getLoadableComponent(() => import('./dials/competitions/Dials.Competitions.page')),


  OnlineDancers: getLoadableComponent(() => import('./onlineDancers/Online.Dancers.page')),
  OnlineActions: getLoadableComponent(() => import('./onlineActions/Online.Actions.page')),
  OnlineData: getLoadableComponent(() => import('./onlineData/Online.Data.page')),

  Adjudicator: getLoadableComponent(() => import('./adjudicator/Adjudicator.page')),
  Master: getLoadableComponent(() => import('./master/Master.page')),

    
}