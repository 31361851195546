import { getToken, errorEvaluation } from '../Utils/Common';
//import React, { useContext } from 'react'
//import { connectReduxDevtools } from 'mst-middlewares'

import { UpgradeOffers } from './upgradeOffers.store'
import { UpgradeOffersScenarios } from './upgradeOffersScenarios.store'

import { types, Instance, flow, applySnapshot } from 'mobx-state-tree'
import { sleep } from '../helpers/sleep'

//import { logout } from '../authProvider'

import * as Constants from '../Constants/Constants'
import axios from 'axios'; 

/*
function isNetworkError(err:any) {
  return !!err.isAxiosError && !err.response;
}
*/

export const RootUpgradeStore = types
  .model({
    upgradeOffers: types.array(UpgradeOffers),
    upgradeOffersScenarios: types.array(UpgradeOffersScenarios),
    
  })
  .views(self => ({
    getUpgradeOffersScenarioById(id: string) {
      return self.upgradeOffersScenarios.find(scenario => scenario.id === id)
    },
    getUpgradeOfferById(id: string) {
      return self.upgradeOffers.find(upgradeOffer => upgradeOffer.id === id)
    },
  }))
  .actions(self => ({

    fetchUpgradeOffers: flow(function* fetchProjects(id: string) {
      try {

       yield sleep(300)
      
       const accessToken = yield getToken()

        const cl = yield axios.get(Constants.URL_OFFERS+id,{
          headers: {
            'Authorization': 'Bearer ' + accessToken
          }}).then(response => {
          //console.log(response.data)
          return response.data
          
        }).catch(error => {
          errorEvaluation(error)
        });
        //console.log(cl)
        applySnapshot(self.upgradeOffers,[cl]) 
          
    } catch (error) {
      console.error('Nepodařilo se načíst nabídky!', error)
      /*sessionStorage.setItem('automaticLogout', '2')        
      removeUserSession()
      logout()*/
    } 
    }),

    fetchUpgradeOffersToCustomer: flow(function* fetchProjects(customerId:string) {
      try {

       yield sleep(300)
      
       const accessToken = yield getToken()

       let url = Constants.URL_CUSTOMER_UPGRADE_OFFERS+customerId+'/offers'

       if ((localStorage.getItem('BigHedgehog')! === '1') || (localStorage.getItem('SmallHedgehog')! === '1')) url = Constants.URL_OFFERS+'customer/'+customerId

        const cl = yield axios.get(url,{
          headers: {
            'Authorization': 'Bearer ' + accessToken
          }}).then(response => {
          //console.log(response.data)
          return response.data
          
        }).catch(error => {
          errorEvaluation(error)
        });
        //console.log(cl)
        applySnapshot(self.upgradeOffers,cl) 
          
    } catch (error) {
      console.error('Nepodařilo se načíst nabídky!', error)
      /*sessionStorage.setItem('automaticLogout', '2')        
      removeUserSession()
      logout()*/
    } 
    }),

    fetchUpgradeOffersFromData: flow(function* fetchProjects(data: any) {
      try {

       yield sleep(300)
      
       
        //console.log(cl)
        applySnapshot(self.upgradeOffers,data) 
          
    } catch (error) {
      console.error('Nepodařilo se načíst nabídky!', error)
      /*sessionStorage.setItem('automaticLogout', '2')        
      removeUserSession()
      logout()*/
    } 
    }),

    fetchUpgradeOffersFromSelf: flow(function* fetchProjects() {
      try {

       yield sleep(300)
       const data = self.upgradeOffers
       self.upgradeOffers.clear();

       (async () => {
        
        
        await applySnapshot(self.upgradeOffers,data) 
       }) ()
        //console.log(cl)
        
          
    } catch (error) {
      console.error('Nepodařilo se načíst nabídky!', error)
      /*sessionStorage.setItem('automaticLogout', '2')        
      removeUserSession()
      logout()*/
    } 
    }),

    deleteUpgradeOffers: flow(function* fetchProjects() {
      try {
        yield sleep(100)
        self.upgradeOffers.clear()
                
      } catch (error) {
        console.error('Failed to clear upgradeOffers', error)
      }
    }),    

    fetchUpgradeOffersScenarios: flow(function* fetchProjects() {
      try {

       yield sleep(300)
      
       const accessToken = yield getToken()

        const cl = yield axios.get(Constants.URL_OFFERS_SCENARIOS,{
          headers: {
            'Authorization': 'Bearer ' + accessToken
          }}).then(response => {
          
          return response.data
          
        }).catch(error => {
          errorEvaluation(error)
        });
        //console.log(cl)
        applySnapshot(self.upgradeOffersScenarios,cl) 
          
    } catch (error) {
      console.error('Nepodařilo se načíst scénáře nabídky!', error)

    } 
    }),

    deleteUpgradeOffersScenarios: flow(function* fetchProjects() {
      try {
        yield sleep(100)
        self.upgradeOffersScenarios.clear()
                
      } catch (error) {
        console.error('Failed to clear upgradeOffersScenarios', error)
      }
    }),      

    

  }))

export type RootUpgradeStoreType = Instance<typeof RootUpgradeStore>
export type UpgradeOffersStoreType = Instance<typeof UpgradeOffers>
export type UpgradeOffersScenariosStoreType = Instance<typeof UpgradeOffersScenarios>