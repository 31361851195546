import { types } from 'mobx-state-tree'


export const OnlineDancers = types
  .model({
    uid: types.optional(types.string, ''),
    actionUid: types.maybeNull(types.string),
    dancersName: types.maybeNull(types.string),
    
  })
  .views(self => ({
    
  }))
  .actions(self => ({
    

  }))
