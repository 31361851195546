import { types } from 'mobx-state-tree'


export const BankAccountTransfersCAL = types
  .model({
    id: types.optional(types.string, ''),
    idTransaction: types.optional(types.string,''),
    date: types.optional(types.string,''),
    variableSymbol: types.maybeNull(types.string),
    specificSymbol: types.maybeNull(types.string),
    amount: types.optional(types.number,0),
    name: types.maybeNull(types.string),
    bankAccount: types.maybeNull(types.string),
    message: types.maybeNull(types.string),
    paired: types.optional(types.boolean,false),

  })
  .views(self => ({
    
    get searchString() {
      //eslint-disable-next-line
      let searchString:string = ''

      let name:string = ''
      if (!(self.name === null || self.name === '')) name = self.name! 

      let bankAccount:string = ''
      if (!(self.bankAccount === null || self.bankAccount === '')) bankAccount = self.bankAccount! 
      
      let message:string = ''
      if (!(self.message === null || self.message === '')) message = self.message! 

      let variableSymbol:string = ''
      if (!(self.variableSymbol === null || self.variableSymbol === '')) variableSymbol = self.variableSymbol! 

      return searchString = name+' '+bankAccount+' '+message+' '+variableSymbol
    },
    
  }))
  .actions(self => ({
    

  }))
