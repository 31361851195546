import { types } from 'mobx-state-tree'

export const DialsClasses = types
  .model({
    idClasses: types.optional(types.string, ''),
    shortcut: types.maybeNull(types.string),
    className: types.maybeNull(types.string),
    active: types.maybeNull(types.string),
  })
  .views(self => ({
    
  }))
  .actions(self => ({
    

  }))
