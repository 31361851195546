import React, {useState}  from 'react'
import Loader from "react-loader"
import { ROUTES } from '../../Routes'
import { NavLink } from 'react-router-dom'

import TopMenu from '../../parts/TopMenu/TopMenu.parts'
import SideMenu from '../../parts/TopMenu/SideMenu.parts'

import FormControl from 'react-bootstrap/FormControl';
import FormGroup from 'react-bootstrap/FormGroup';
import FormLabel from 'react-bootstrap/FormLabel'
import { Formik } from 'formik'
import * as Yup from 'yup'

import { Container, CardDeck, Alert, Row, Col, Table, Badge, Form } from 'react-bootstrap'

import { useMount } from '../../helpers/lifecycle-hooks'
// eslint-disable-next-line
import { useStore,useDialsStore, useUpgradeStore } from '../../store'
import { observer } from 'mobx-react'
import { getToken, handleMenuContent } from '../../Utils/Common'

import moment from 'moment'
import NumberFormat from 'react-number-format'

import Pagination from 'react-js-pagination'

function BankAccountTransfersCPADU(props:any) {
  // Nastavení proměnné pro Loader
  const [loaded, setLoaded] = useState(true);
  const [loading, setLoading] = useState(false)
  const [page, setPage] = useState(1)
  // Nastavení práv zda jsme Ježci
  
  const [filterName, setFilterName] = useState(!(localStorage.getItem('transfersCPADUFilterName') === undefined || localStorage.getItem('transfersCPADUFilterName') === null)  ? localStorage.getItem('transfersCPADUFilterName') : '');
  //const [filterId, setFilterId] = useState(!(localStorage.getItem('memberCPADUFilterId') === undefined || localStorage.getItem('memberCPADUFilterId') === null)  ? localStorage.getItem('memberCPADUFilterId') : '');

  // Načtení Store
  const rootStore = useStore()
  //const rootDialsStore = useDialsStore()
  //const rootUpgradeStore = useUpgradeStore()

  //rootUpgradeStore.fetchUpgradeOffersScenarios()
  //const customer = rootStore.getOpenedCustomerById(localStorage.getItem('openedCustomerId')!)
  
  // Nastavení pro stránkování
  const itemsPerPage = 25

  const indexOfLastItem = page * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;

  // Při načtení stránky 
  useMount(() => {
    (async () => {  
    setLoaded(false) // Nastavení pro Loader, že není strana načtena
    window.scrollTo(0, 0)
    
    // Kontrola přihlášení  
    //const aToken = await getToken()
    if (localStorage.getItem('isLogged') !== 'is') {   
      sessionStorage.setItem('automaticLogout', '1')
      props.history.push('/logout')
    }
    else {
      //Načtení souvisejících číselníků podle práv
      


      //Pokud je vyvolaná F5
      if (window.performance) {
        if (performance.navigation.type > 0 || sessionStorage.getItem('isIn') === null) {
          await rootStore.fetchLoggedUsers(localStorage.getItem('email')!, localStorage.getItem('PIN')!)
          //await rootStore.fetchCustomerOpened()
          console.log( "This page is reloaded" )
          sessionStorage.setItem('isIn', '1')
        } else {
          
        }
      } 
      //Konec sekce F5

      await rootStore.fetchBankAccountTransfersCPADUList(rootStore.loggedUsers[0]?.idUsers, localStorage.getItem('PIN')!)

      //Pokud mám práva tak vždy načítám další položky 
      setLoaded(true) // Nastavení pro Loader, že je strana načtena

      

    }
    /*if (rootStore.loggedUsers[0] === undefined) {   
      sessionStorage.setItem('automaticLogout', '1')
      props.history.push('/logout')
    }    */ 
    setLoaded(true) // Nastavení pro Loader, že je strana načtena
    })() 
  })

  //Počet záznamů
  const countData:number = rootStore.bankAccountTansfersCPADU
  .filter(data => data.paired === false)
  .filter(data => {if (filterName === '') return data; else return data.searchString!.toLowerCase().indexOf(filterName!.toLowerCase()) >= 0  })
  //.filter(data => {if (filterId === '') return data; else return data.searchStringId!.toLowerCase().indexOf(filterId!.toLowerCase()) >= 0  })
  .length

    // Seznam zákazníků do řádků tabulky
    const transfersDataTable = rootStore.bankAccountTansfersCPADU
    .filter(data => data.paired === false)
    .filter(data => {if (filterName === '') return data; else return data.searchString!.toLowerCase().indexOf(filterName!.toLowerCase()) >= 0  })
    //.filter(data => {if (filterId === '') return data; else return data.searchStringId!.toLowerCase().indexOf(filterId!.toLowerCase()) >= 0  })
    .slice(indexOfFirstItem, indexOfLastItem)
  
    .map(transferList => (
      <tr key={transferList.id}>
        <td>{transferList.idTransaction}</td>
        <td>{moment(transferList.date).format("DD.MM.YYYY")}</td>
        <td>{transferList.variableSymbol}</td>
        <td>{transferList.specificSymbol}</td>
        <td style={{textAlign: 'right'}}><NumberFormat displayType={'text'} thousandSeparator={'.'} decimalSeparator={','} decimalScale={2} fixedDecimalScale={true} value={transferList.amount} /></td>
        <td>{transferList.name}</td>
        <td>{transferList.bankAccount}</td>
        <td>{transferList.message}</td>
        <td>{transferList.paired ? <Badge variant="success">Spárováno</Badge> : <Badge variant="danger">Nespárováno</Badge>}</td>    
        <td>

        </td>
      </tr>
    ))

    const handlePageChange = (pageNumber:number) => {
      (async () => { 
        setLoading(true);

        sessionStorage.setItem('transfersCPADUListSearchPage', String(pageNumber))
        // Předat hledanou hodnotu action pro načtení zákazníků
        setPage(pageNumber)
        //await rootInvoicesStore.fetchInvoicesList(pageNumber, props.invoiceType)
        setLoading(false);  
      })()
    }

    const schemaUpdate = Yup.object().shape({
  
    })

  return (
    <div className="App">
      
        <TopMenu />

        <div id="layoutSidenav">
          <SideMenu />
          <div id="layoutSidenav_content" onClick={handleMenuContent}>
            <main>
            <Loader 
              loaded={loaded}
              lines={13}
              length={20}
              width={10}
              radius={30}
              corners={1}
              rotate={0}
              direction={1}
              color="#000"
              speed={1}
              trail={60}
              shadow={true}
              hwaccel={true}
              className="spinner"
              zIndex={2e9}
              top="50%"
              left="50%"
              scale={1.0}
            >
              
              <Container fluid>
                <br />
                <h4>Seznam nespárovaných plateb</h4>
                <br />
                <Alert variant="info">
                  <Formik
                    validationSchema={schemaUpdate}
                    initialValues={{ 
                      //memberCPADUId: !(localStorage.getItem('memberCPADUFilterId') === undefined || localStorage.getItem('memberCPADUFilterId') === null)  ? localStorage.getItem('memberCPADUFilterId') : '',
                      transfersCPADUName: !(localStorage.getItem('transfersCPADUFilterName') === undefined || localStorage.getItem('transfersCPADUFilterName') === null)  ? localStorage.getItem('transfersCPADUFilterName') : '',
                      
                    }}
                    onSubmit={(values, { setSubmitting }) => {
                      setTimeout(async () => {
                        //handleInsert(values.idLecturers!)
                        await setLoading(true)
                        await localStorage.setItem('transfersCPADUFilterName', values.transfersCPADUName!)
                        //await localStorage.setItem('memberCPADUFilterId', values.memberCPADUId!)

                        await setFilterName(values.transfersCPADUName!)
                        //await setFilterId(values.memberCPADUId!)

                        await sessionStorage.setItem('transfersCPADUListSearchPage', String(1))
                
                        await setPage(1)
                        await setLoading(false)

                        setSubmitting(false);
                      }, 100);
                    }}
                  >
                    {({ 
                      handleSubmit,
                      handleChange,
                    
                      values,
                      touched,
                      isValid,
                      errors,
                      isSubmitting }) => (
                      <Form onSubmit={handleSubmit}>
                        <Form.Row>
                        
                        

                        <FormGroup controlId="transfersCPADUName" style={{paddingLeft: '40px', marginBottom: '0px'}}>
                        <Form.Row>
                        <FormLabel column lg={4}>Hledaný text</FormLabel>
                            <Col>
                              <FormControl 
                                type="text"
                                name="transfersCPADUName"
                                value={values.transfersCPADUName!}
                                onChange={async (e:any) => { await handleChange(e); await handleSubmit(e); }}
                                //isValid={touched.educationName && !errors.educationName} 
                                //isInvalid={!!errors.educationName}
                                placeholder='hledaný text'
                                autoFocus
                              />
                              
                              <Form.Control.Feedback type="invalid">
                                {errors.transfersCPADUName}
                              </Form.Control.Feedback>
                            </Col>
                            </Form.Row>
                        </FormGroup> 

                        

                        

                        </Form.Row>
                        
                      </Form>
                    )}
                  </Formik>  
                  
                </Alert>
                <br />
                
                <Table responsive hover>
                      <thead>
                        <tr className="jsw-table-head">
                        <th className="jsw-table-head row-slim">ID tr.</th>
                          <th className="jsw-table-head row-slim">Datum</th>
                          <th className="jsw-table-head">Variabilní s.</th>
                          <th className="jsw-table-head">Specifický s.</th>
                          <th className="jsw-table-head" style={{textAlign: 'right'}}>Částka</th>
                          <th className="jsw-table-head">Název účtu</th>
                          <th className="jsw-table-head">Účet</th>
                          <th className="jsw-table-head">Zpráva</th>
                          <th className="jsw-table-head row-slim">Stav</th>
                          <th className="jsw-table-head">Akce</th>
                        </tr>
                      </thead>
                      <tbody>
                        {transfersDataTable}
                      </tbody>
                    </Table> 

                    <br />   
                    {!loading &&
                    <Pagination
                      itemClass="page-item"
                      linkClass="page-link"
                      activePage={Number(sessionStorage.getItem('transfersCPADUListSearchPage')) === 0 ? 1:Number(sessionStorage.getItem('transfersCPADUListSearchPage')) }
                      itemsCountPerPage={itemsPerPage}
                      totalItemsCount={countData}
                      pageRangeDisplayed={10}
                      onChange={handlePageChange.bind(1)}
                      /> }

                    {countData === 0 ? <div>Seznam nespárovaných plateb neobsahuje žádné záznamy.<br /><br /></div> : ''}

                <Row>
                  <Col style={{minWidth:'300px', marginTop: '25px'}}>
                    
                    
                  </Col>
                  <Col style={{minWidth:'300px', marginTop: '25px'}}>
                    
                  </Col>
                </Row>
                
                

                <br /><br />
              </Container>
              </Loader>
            </main>
          </div>
        </div>
      
    </div> 
  );
}

export default observer(BankAccountTransfersCPADU)
