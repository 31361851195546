import { types } from 'mobx-state-tree'

const Module = types.model('Module',{
  id: types.optional(types.string, ''),
  name: types.maybeNull(types.string),
  code: types.maybeNull(types.string),
  programId: types.maybeNull(types.string)
})

export const DialsPriceListsModules = types
  .model({
    id: types.optional(types.string, ''),
    price: types.maybeNull(types.number),
    module: types.frozen(Module),
    
  })
  .views(self => ({
    
  }))
  .actions(self => ({
    

  }))
