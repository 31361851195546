import React from 'react'
import { NavLink, Link, withRouter } from 'react-router-dom'

import { ROUTES } from '../../Routes'
import { Navbar,NavDropdown,Button, Form } from 'react-bootstrap'

//import { useStore } from '../../store'
import { observer } from 'mobx-react'

function TopMenu(props:any) {
    // Připojení store
    //const rootStore = useStore();

    // Nastavení práv zda jsme Ježci
    const rights:boolean = ((localStorage.getItem('BigHedgehog')! === '1') || (localStorage.getItem('SmallHedgehog')! === '1') ) ? true : false
    
    const companyToShow:string = localStorage.getItem('userNameMenu') === undefined ? 'nepřihlášen' : localStorage.getItem('userNameMenu')!

    //var company:string | undefined = localStorage.getItem('userNameMenu')!
    
    /*if (localStorage.getItem('openedCustomerId') !== null ) {
      const customer = rootStore.getOpenedCustomerById(localStorage.getItem('openedCustomerId')!)   
      company = customer?.name
    }
    */
    // var companyToShow:string  = company === undefined ? 'nepřihlášen' : company
    
    const handleMenu = () => {
        document.body.classList.toggle('sb-sidenav-toggled');
      }

      



    if (localStorage.getItem('isLogged')) {
        return( 
            <Navbar bg="dark" variant="dark" expand="lg" className="sb-topnav" >
                <Navbar.Brand><Link className="navbar-brand" to="/"><img alt="" src="/sut-logo-kruh-male.jpg" width="23" height="23" className="d-inline-block align-top" />{' '}{' '}<span style={sessionStorage.getItem("isTestRelease") === '10' ? {color: 'red'} : {}}>{sessionStorage.getItem("isTestRelease") === '1' ? 'Informační systém':'Informační systém'}</span></Link></Navbar.Brand>
                <button className="btn btn-link btn-sm order-1 order-lg-0 colapsed" id="sidebarToggle" onClick={handleMenu}><span className="navbar-toggler-icon"></span></button>

                <Navbar.Collapse  className="justify-content-end">
                    <NavDropdown title={localStorage.getItem('BigUserBefore') === "0" ?  <span style={{color: '#e74a3b'}}><i className="fa fa-flask fa-fw"></i> {companyToShow}</span>:<span><i className="fa fa-user fa-fw"></i> {companyToShow}</span>} id="collasible-nav-dropdown">            
                    <NavLink className="dropdown-item" to={ROUTES.myAccount.getLink()}><i className="fa fa-fingerprint fa-fw"></i>&nbsp;&nbsp;Můj účet</NavLink>
                   


                    <NavLink className="dropdown-item" to={ROUTES.logout.getLink()}><Button variant="danger" size="sm" block>Odhlásit</Button></NavLink>
                </NavDropdown>
            </Navbar.Collapse> 
                                  
          </Navbar>

        )
    }
    else {
        return(
            <Navbar bg="dark" variant="dark" expand="lg" className="sb-topnav" >
                <Navbar.Brand><Link className="navbar-brand" to="/"><img alt="" src="/sut-logo-kruh-male.jpg" width="50" height="23" className="d-inline-block align-top" />{' '}{' '}Informační systém</Link></Navbar.Brand>                      
            </Navbar>
        
        )  
    }
      
}
  
export default observer(withRouter(TopMenu))
