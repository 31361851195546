import { types } from 'mobx-state-tree'

export const Countries = types
  .model({
    code: types.optional(types.string, ''),
    cca2: types.optional(types.string, ''),
    cca3: types.optional(types.string, ''),
    nameCz: types.optional(types.string, ''),
    nameEn: types.optional(types.string, ''),
    namePl: types.optional(types.string, ''),
    nameHu: types.optional(types.string, ''),
    flag: types.optional(types.string, ''),
    priority: types.optional(types.number, 9999),
  })
  .views(self => ({
    get finalCode() {
      return self.code === '' ? self.cca3 : self.code
    },
  }))
  .actions(self => ({
    

  }))
