import { types } from 'mobx-state-tree'


export const CompetitionAppListWithStartCountsList = types
  .model({
    surName: types.optional(types.string, ''),
    firstName: types.optional(types.string, ''),
    year: types.optional(types.string, ''),
    
    club: types.optional(types.string, ''),
    country: types.optional(types.string, ''),
    
    countCouples: types.optional(types.number, 0),
    countSolos: types.optional(types.number, 0),
    countDuos: types.optional(types.number, 0),
    countTeams: types.optional(types.number, 0),
    countFormations: types.optional(types.number, 0),

    priceCouples: types.optional(types.number, 0),
    priceSpecial: types.optional(types.number, 0),
  })
  .views(self => ({
    get total() {
      return self.countCouples + self.countSolos + self.countDuos + self.countTeams + self.countFormations
    }
  }))
  .actions(self => ({
    

  }))
