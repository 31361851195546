import { types } from 'mobx-state-tree'

const Program = types.model('Program',{
  id: types.maybeNull(types.string),
  name: types.maybeNull(types.string),
  code: types.maybeNull(types.string)
})

const ProgramVersion = types.model('ProgramVersion',{
  id: types.maybeNull(types.string),
  name: types.maybeNull(types.string),
  version: types.maybeNull(types.number),
  programId: types.maybeNull(types.string)
})

export const UpgradeOffersScenarios = types
  .model({
    id: types.optional(types.string, ''),
    program: types.frozen(Program),
    programVersion: types.frozen(ProgramVersion),
  })
  .views(self => ({

  }))
  .actions(self => ({
    

  }))
