import { types } from 'mobx-state-tree'

export const Invites = types
  .model({
    id: types.optional(types.string, ''),
    email: types.optional(types.string, ''),
    createdAt: types.maybeNull(types.string),
    expiredAt: types.maybeNull(types.string),
    usedAt: types.maybeNull(types.string),
    isCustomerAdmin: types.optional(types.boolean, false)
   })
  .views(self => ({

  }))
  .actions(self => ({
    

  }))
