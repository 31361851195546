import { types } from 'mobx-state-tree'


export const CompetitionsList = types
  .model({
    idCompetitions: types.optional(types.string, ''),
    date: types.maybeNull(types.string),
    dateTo: types.maybeNull(types.string),
    name: types.maybeNull(types.string),
    logo: types.maybeNull(types.string),
    applicationsDate: types.maybeNull(types.string),
    allowSolos: types.maybeNull(types.string),
    allowDuets: types.maybeNull(types.string),
    allowTeams: types.maybeNull(types.string),
    allowProams: types.maybeNull(types.string),

    allowCouples: types.maybeNull(types.string),
    allowPara: types.maybeNull(types.string),

    allowFormations: types.maybeNull(types.string),
    description: types.maybeNull(types.string),
    labelSolo: types.optional(types.string,''),
    labelSoloEn: types.optional(types.string,''),
    labelDuo: types.optional(types.string,''),
    labelDuoEn: types.optional(types.string,''),
    labelCouple: types.optional(types.string,''),
    labelCoupleEn: types.optional(types.string,''),
    labelTeam: types.optional(types.string,''),
    labelTeamEn: types.optional(types.string,''),
    showAppDetailCompetitions: types.optional(types.string,'0'),
    cpadu: types.optional(types.boolean,false),
    sut: types.optional(types.boolean,false),
    allowPromoCode: types.optional(types.boolean,false),
  })
  .views(self => ({
    get applicationsAfterTerm() {
      console.log(new Date(self.applicationsDate!).setHours(0, 0, 0, 0))
      console.log(new Date().setHours(0, 0, 0, 0))
      if (new Date(self.applicationsDate!).setHours(0, 0, 0, 0) > new Date().setHours(0, 0, 0, 0)) {console.log(false)} else {console.log(true)}

      return new Date(self.applicationsDate!).setHours(0, 0, 0, 0) >= new Date().setHours(0, 0, 0, 0) ? false:true
    },
  }))
  .actions(self => ({
    

  }))
