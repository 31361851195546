import { types } from 'mobx-state-tree'

export const DialsCompetitionTypes = types
  .model({
    idCompetitionTypes: types.optional(types.string, ''),
    competitionTypeShortcut: types.maybeNull(types.string),
    competitionTypeName: types.maybeNull(types.string),
    onlyForMainAgeCategories: types.maybeNull(types.string),
    onlyForOwnAgeCategory: types.maybeNull(types.string),
    active: types.maybeNull(types.string),
  })
  .views(self => ({
    get isOnlyForMainCategories() {
      
      return self.onlyForMainAgeCategories === '1' ? true:false
    },
    get isOnlyForOwnAgeCategory() {
      
      return self.onlyForOwnAgeCategory === '1' ? true:false
    },
  }))
  .actions(self => ({
    

  }))
