import { types } from 'mobx-state-tree'



export const MembersSoloDuo = types
  .model({
    id: types.optional(types.string, ''),
    surName: types.maybeNull(types.string),
    firstName: types.maybeNull(types.string),
    date: types.maybeNull(types.string),
    club: types.maybeNull(types.string),
    country: types.maybeNull(types.string),
    classSoloSTT: types.optional(types.string, ''),
    classSoloLAT: types.optional(types.string, ''),
    classSoloCAR: types.optional(types.string, ''),
    lastPaidYear: types.optional(types.number, 0),
    medicalExaminationTo: types.maybeNull(types.string),
  })
  .views(self => ({
    get classSoloSTTName() {
      let name = ''
      if (self.classSoloSTT === 'H') name = 'Hobby-D'
      if (self.classSoloSTT === 'S') name = 'Sport-C'
      return name
    },
    get classSoloLATName() {
      let name = ''
      if (self.classSoloLAT === 'H') name = 'Hobby-D'
      if (self.classSoloLAT === 'S') name = 'Sport-C'
      return name
    },
    get classSoloCARName() {
      let name = ''
      if (self.classSoloCAR === 'H') name = 'Hobby-D'
      if (self.classSoloCAR === 'S') name = 'Sport-C'
      return name
    },
  }))
  .actions(self => ({
    updateMemberFromData(data: any) {

      self.id = data.id
      self.surName = data.surName
      self.firstName = data.firstName
      self.date = data.date
      self.club = data.club
      self.country = data.country
      self.classSoloSTT = data.classSoloSTT
      self.classSoloLAT = data.classSoloLAT
      self.classSoloCAR = data.classSoloCAR
      self.lastPaidYear = data.lastPaidYear
      self.medicalExaminationTo = data.medicalExaminationTo
    }

  }))
