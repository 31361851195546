import { types } from 'mobx-state-tree'

export const LoggedUser = types
  .model({
    idUsers: types.optional(types.string, ''),
    fullName: types.optional(types.string, ''),
    login: types.optional(types.string, ''),
    permissions: types.optional(types.string, '')
  })
  .views(self => ({
    
  }))
  .actions(self => ({
    
    
  }))
